<template>
    <div>
        <div class="mt-3 card p-2 rounded d-flex flex-column px-5 mb-5 justify-content-between">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="d-flex flex-column justify-content-around align-items-center">
                    <router-link :to="{ name: 'classes/edit', params: { id: lesson.classe_id } }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            Quitter l'editeur de cours
                        </span>
                    </router-link>
                </div>
                <div style="max-width:50%" class="d-flex flex-column justify-content-between align-items-center">
                    <div>
                        Titre du cours :
                        <span class="font-weight-bold">
                            {{ lesson.title }}
                        </span>
                    </div>
                    <div
                        style="max-width : 100%; max-height:4vh"
                        class="truncate text-center d-flex align-items-center"
                        v-b-modal.description-modal
                        v-b-tooltip.hover.bottom="'Voir la description complète'"
                    >
                        <span>Description :</span>
                        <nl2br
                            v-if="lesson.description"
                            tag="p"
                            :text="lesson.description"
                            class-name="px-3 mb-0 border-secondary border-right align-self-start nl2br font-weight-bold"
                        />
                    </div>
                    <div v-if="lesson.min_duration">
                        Durée minimum :
                        <span class="font-weight-bold">{{ lesson.min_duration }} minutes</span>
                    </div>
                    <b-modal button-size="sm" id="description-modal" title="Description complète">
                        <nl2br tag="p" v-if="lesson.description" :text="lesson.description" />
                        <template #modal-footer="{ok}">
                            <b-button variant="secondary" @click="ok()">{{ $t('CONTROLLERS.CLOSE') }}</b-button>
                        </template>
                    </b-modal>
                </div>
                <div class="d-flex flex-column justify-content-between align-items-center">
                    <span class="text-hover-primary">
                        Créé le :
                        <span class="font-weight-bold">{{ formatDate(lesson.created_at) }}</span>
                    </span>
                    <span class="text-hover-primary" v-if="this.lesson.updated_at != null">
                        Mis à jour le :
                        <span class="font-weight-bold">{{ formatDate(lesson.updated_at) }}</span>
                    </span>
                    <span>
                        Parcours ordonné :
                        <span class="font-weight-bold">{{ lesson.linear ? 'Oui' : 'Non' }}</span></span
                    >
                </div>
                <div>
                    <div class="d-flex flex-row justify-content-around align-items-center border border-primary rounded-pill px-2 mx-2">
                        <router-link
                            :to="{
                                name: 'lessons/update',
                                params: { id: this.$route.params.id }
                            }"
                            replace
                        >
                            <button class="svg-icon svg-icon-md svg-icon-primary px-2" v-b-tooltip.hover.bottom="'Modifier le cours'">
                                <inline-svg src="/media/svg/icons/General/Edit.svg" />
                            </button>
                        </router-link>
                        <div class="border-primary" style="height: 4vh;width:1px;border-style:dotted"></div>
                        <router-link :to="{ name: 'lessonItems', params: { id: lesson.id } }" replace>
                            <button class="svg-icon svg-icon-md svg-icon-primary px-2" v-b-tooltip.hover.bottom="'Prévisualiser'">
                                <inline-svg src="/media/svg/icons/Media/Play.svg" />
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 card p-2 rounded" style="overflow:hidden">
            <div class="d-flex flex-row justify-content-between" style="position: relative; background-color:white;height: 72vh">
                <div
                    class="drop-zone border-white border-right"
                    style="
                        height: 100%;
                        background-color: white;
                        margin-bottom: 10px;
                        padding: 10px;
                        min-width: 20%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    "
                >
                    <div class="px-3 scrollPanel" style="overflow-y:scroll; height: 100%;">
                        <div
                            onmouseover="this.style.opacity=0.9"
                            onmouseout="this.style.opacity=1"
                            :style="'background-image:url(' + item.src + '); background-size: contain;'"
                            style="margin-bottom:10px;height:15vh;position:relative;color:white;background-position:center;"
                            class="drag-el border border-white text-center"
                            v-for="item in items"
                            :key="item.title"
                            draggable
                            @dragstart="startDrag($event, item)"
                            @dragend="exitDrag()"
                            @dblclick="addItemToComposition(item)"
                        >
                            <div class="py-2 mb-1" style="width:100%;position:absolute;bottom:0;background-color:black;opacity:0.7">
                                {{ item.title }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="drop-zone scrollPanel"
                    style="
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-color: white;
                        margin-bottom: 10px;
                        padding: 10px;
                        width: 80%;
                        overflow-y: scroll;
                        height: 100%;
                    "
                >
                    <div
                        class="drag-el"
                        v-for="item in lessonItems"
                        :key="item.order"
                        :draggable="`${item.type == 'end' || item.type == 'start' ? false : true}`"
                        @dragstart="startShifting($event, item)"
                        @dragend="exitDrag()"
                    >
                        <!--  BEGIN DROP ZONE TOP ITEM --->
                        <span v-if="mooveHoverOrder == item.order">
                            <div
                                v-if="lessonItems[onDragOrder].type != 'end'"
                                class="moove-zone rounded"
                                @drop="itemsOrderReorganization($event, item)"
                                @dragover.prevent
                                @dragenter.prevent
                                style="background-color: #F7F5F5; margin-bottom: 10px; padding: 10px; height: 4vh; width : 100%"
                            />
                        </span>
                        <!-- END DROP ZONE TOP ITEM -->
                        <div
                            v-if="item.order != 0"
                            onmouseover="this.style.background='#eef';"
                            onmouseout="this.style.background='#eee';"
                            @dragenter="onDragHover(item.order)"
                            style="background-color: #eee; margin-bottom: 10px; padding: 5px;z-index: 999"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <div style="width: 3%" class="text-center" @click="showAllItem(item.order)">
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <inline-svg
                                            :src="
                                                '/media/svg/icons/Navigation/' +
                                                    `${detailsShowing == item.order ? 'Minus' : 'Plus'}` +
                                                    '.svg'
                                            "
                                        />
                                    </span>
                                </div>
                                <div style="width: 8%" @click="showAllItem(item.order)">#{{ item.order }}</div>
                                <div style="width: 70%" class="text-center" @click="showAllItem(item.order)">
                                    {{ item.type }} |
                                    <span class="h5 text-primary">{{ item.title }}</span>
                                </div>
                                <div style="width: 20%" class="text-right">
                                    <div class="d-flex flex-row align-items-center justify-content-end">
                                        <span
                                            v-if="
                                                (item.type == 'questionnaire' && item.order == lessonItems[lessonItems.length - 1].order) ||
                                                    (item.type == 'end' && lessonItems[lessonItems.length - 1].order)
                                            "
                                            @click="endComposition(item.order)"
                                            class="svg-icon svg-icon-md mr-1"
                                            v-b-tooltip.hover.top="
                                                `${
                                                    item.type != 'end'
                                                        ? 'Définir en tant qu\'examen final'
                                                        : 'Enlever le status d\'examen final'
                                                }`
                                            "
                                            :class="`${item.type == 'end' ? 'svg-icon-warning' : 'svg-icon-muted'}`"
                                        >
                                            <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                                        </span>
                                        <span
                                            @click="editItem(item.order)"
                                            v-b-tooltip.hover.top="'Modifier'"
                                            class="svg-icon svg-icon-md svg-icon-primary mx-1"
                                        >
                                            <inline-svg src="/media/svg/icons/General/Edit.svg" />
                                        </span>
                                        <span
                                            @click="deleteItemId = item.order"
                                            v-b-modal.delete-modal
                                            v-b-tooltip.hover.top="'Supprimer'"
                                            class="svg-icon svg-icon-md svg-icon-danger"
                                        >
                                            <inline-svg src="/media/svg/icons/General/Trash.svg" />
                                        </span>
                                        <router-link
                                            :to="{
                                                name: 'lessonItems',
                                                params: { id: lesson.id, item: item.order }
                                            }"
                                            replace
                                        >
                                            <button
                                                class="svg-icon svg-icon-md svg-icon-primary px-1"
                                                v-b-tooltip.hover.top="'Prévisualiser'"
                                            >
                                                <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                            </button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="item.order != 0 && detailsShowing == item.order"
                                class="lessonItems mt-3 pt-2 border-top border-secondary d-flex flex-column justify-content-between"
                            >
                                <template v-if="item.type == 'question'">
                                    <div class="d-flex flex-row justify-content-around">
                                        <div style="width:55%" class="p-3">
                                            <p class="text-bold mb-2">{{ item.test[0].question }}</p>
                                            <ul style="list-style:none;width:100%" class="d-flex flex-column align-items-center">
                                                <li style="width:80%" class="d-flex flex-row justify-content-between">
                                                    <span
                                                        style="width:9%"
                                                        class="svg-icon svg-icon-sm"
                                                        :class="
                                                            `${
                                                                item.test[0].goodAnswers.includes('A')
                                                                    ? 'svg-icon-primary'
                                                                    : 'svg-icon-danger'
                                                            }`
                                                        "
                                                    >
                                                        <inline-svg
                                                            :src="
                                                                '/media/svg/icons/Code/' +
                                                                    `${item.test[0].goodAnswers.includes('A') ? 'Done' : 'Error'}` +
                                                                    '-circle.svg'
                                                            "
                                                        />
                                                    </span>
                                                    <span
                                                        style="width:90%"
                                                        :style="
                                                            `${
                                                                item.test[0].goodAnswers.includes('A') ? '' : 'text-decoration:line-through'
                                                            }`
                                                        "
                                                    >
                                                        {{ item.test[0].answerA }}
                                                    </span>
                                                </li>
                                                <li style="width:80%" class="d-flex flex-row justify-content-between">
                                                    <span
                                                        style="width:9%"
                                                        class="svg-icon svg-icon-sm"
                                                        :class="
                                                            `${
                                                                item.test[0].goodAnswers.includes('B')
                                                                    ? 'svg-icon-primary'
                                                                    : 'svg-icon-danger'
                                                            }`
                                                        "
                                                    >
                                                        <inline-svg
                                                            :src="
                                                                '/media/svg/icons/Code/' +
                                                                    `${item.test[0].goodAnswers.includes('B') ? 'Done' : 'Error'}` +
                                                                    '-circle.svg'
                                                            "
                                                        />
                                                    </span>
                                                    <span
                                                        style="width:90%"
                                                        :style="
                                                            `${
                                                                item.test[0].goodAnswers.includes('B') ? '' : 'text-decoration:line-through'
                                                            }`
                                                        "
                                                    >
                                                        {{ item.test[0].answerB }}
                                                    </span>
                                                </li>
                                                <li style="width:80%" class="d-flex flex-row justify-content-between">
                                                    <span
                                                        style="width:9%"
                                                        class="svg-icon svg-icon-sm"
                                                        :class="
                                                            `${
                                                                item.test[0].goodAnswers.includes('C')
                                                                    ? 'svg-icon-primary'
                                                                    : 'svg-icon-danger'
                                                            }`
                                                        "
                                                    >
                                                        <inline-svg
                                                            :src="
                                                                '/media/svg/icons/Code/' +
                                                                    `${item.test[0].goodAnswers.includes('C') ? 'Done' : 'Error'}` +
                                                                    '-circle.svg'
                                                            "
                                                        />
                                                    </span>
                                                    <span
                                                        style="width:90%"
                                                        :style="
                                                            `${
                                                                item.test[0].goodAnswers.includes('C') ? '' : 'text-decoration:line-through'
                                                            }`
                                                        "
                                                    >
                                                        {{ item.test[0].answerC }}
                                                    </span>
                                                </li>
                                                <li style="width:80%" class="d-flex flex-row justify-content-between">
                                                    <span
                                                        style="width:9%"
                                                        class="svg-icon svg-icon-sm"
                                                        :class="
                                                            `${
                                                                item.test[0].goodAnswers.includes('D')
                                                                    ? 'svg-icon-primary'
                                                                    : 'svg-icon-danger'
                                                            }`
                                                        "
                                                    >
                                                        <inline-svg
                                                            :src="
                                                                '/media/svg/icons/Code/' +
                                                                    `${item.test[0].goodAnswers.includes('D') ? 'Done' : 'Error'}` +
                                                                    '-circle.svg'
                                                            "
                                                        />
                                                    </span>
                                                    <span
                                                        style="width:90%"
                                                        :style="
                                                            `${
                                                                item.test[0].goodAnswers.includes('D') ? '' : 'text-decoration:line-through'
                                                            }`
                                                        "
                                                    >
                                                        {{ item.test[0].answerD }}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div style="width:40%">
                                            <div class="border border-secondary rounded p-3" style="height : 100%">
                                                Message affiché en cas d'echec :
                                                <p class="p-3 m-1">{{ item.message }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="item.type == 'questionnaire' || item.type == 'end'">
                                    <div style="width:100%" class="d-flex flex-column justify-content-around">
                                        <div style="width:100%" class="d-flex flex-row justify-content-around align-items-center">
                                            <div class="m-1 p-3" style="width:60%">
                                                <ul style="list-style:none;width:100%">
                                                    <li>Description : {{ item.description }}</li>
                                                    <li class="mt-1">Objectif : {{ item.goal }}%</li>
                                                </ul>
                                            </div>
                                            <div class="m-1 p-3 border border-secondary rounded" style="width:60%;height:100%">
                                                Message affiché en cas d'echec :
                                                <p class="p-3 m-1">{{ item.message }}</p>
                                            </div>
                                        </div>
                                        <div style="width:100%" class="mt-2">
                                            <ul style="list-style:none;width:90%;margin:0 auto;">
                                                <li
                                                    v-for="question in item.test"
                                                    :key="question.id"
                                                    class="border-secondary border-bottom mb-1"
                                                >
                                                    <div class="font-italic">{{ question.question }}</div>
                                                    <div style="width:100%" class="d-flex flow-row justify-content-around">
                                                        <div class="mx-1">
                                                            <span
                                                                class="svg-icon svg-icon-sm"
                                                                :class="
                                                                    `${
                                                                        question.goodAnswers.includes('A')
                                                                            ? 'svg-icon-primary'
                                                                            : 'svg-icon-danger'
                                                                    }`
                                                                "
                                                            >
                                                                <inline-svg
                                                                    :src="
                                                                        '/media/svg/icons/Code/' +
                                                                            `${question.goodAnswers.includes('A') ? 'Done' : 'Error'}` +
                                                                            '-circle.svg'
                                                                    "
                                                                />
                                                            </span>
                                                            <span
                                                                :style="
                                                                    `${
                                                                        question.goodAnswers.includes('A')
                                                                            ? ''
                                                                            : 'text-decoration:line-through'
                                                                    }`
                                                                "
                                                            >
                                                                {{ question.answerA }}
                                                            </span>
                                                        </div>
                                                        <div class="mx-1">
                                                            <span
                                                                class="svg-icon svg-icon-sm"
                                                                :class="
                                                                    `${
                                                                        question.goodAnswers.includes('B')
                                                                            ? 'svg-icon-primary'
                                                                            : 'svg-icon-danger'
                                                                    }`
                                                                "
                                                            >
                                                                <inline-svg
                                                                    :src="
                                                                        '/media/svg/icons/Code/' +
                                                                            `${question.goodAnswers.includes('B') ? 'Done' : 'Error'}` +
                                                                            '-circle.svg'
                                                                    "
                                                                />
                                                            </span>
                                                            <span
                                                                :style="
                                                                    `${
                                                                        question.goodAnswers.includes('B')
                                                                            ? ''
                                                                            : 'text-decoration:line-through'
                                                                    }`
                                                                "
                                                            >
                                                                {{ question.answerB }}
                                                            </span>
                                                        </div>
                                                        <div class="mx-1">
                                                            <span
                                                                class="svg-icon svg-icon-sm"
                                                                :class="
                                                                    `${
                                                                        question.goodAnswers.includes('C')
                                                                            ? 'svg-icon-primary'
                                                                            : 'svg-icon-danger'
                                                                    }`
                                                                "
                                                            >
                                                                <inline-svg
                                                                    :src="
                                                                        '/media/svg/icons/Code/' +
                                                                            `${question.goodAnswers.includes('C') ? 'Done' : 'Error'}` +
                                                                            '-circle.svg'
                                                                    "
                                                                />
                                                            </span>
                                                            <span
                                                                :style="
                                                                    `${
                                                                        question.goodAnswers.includes('C')
                                                                            ? ''
                                                                            : 'text-decoration:line-through'
                                                                    }`
                                                                "
                                                            >
                                                                {{ question.answerC }}
                                                            </span>
                                                        </div>
                                                        <div class="mx-1">
                                                            <span
                                                                class="svg-icon svg-icon-sm"
                                                                :class="
                                                                    `${
                                                                        question.goodAnswers.includes('D')
                                                                            ? 'svg-icon-primary'
                                                                            : 'svg-icon-danger'
                                                                    }`
                                                                "
                                                            >
                                                                <inline-svg
                                                                    :src="
                                                                        '/media/svg/icons/Code/' +
                                                                            `${question.goodAnswers.includes('D') ? 'Done' : 'Error'}` +
                                                                            '-circle.svg'
                                                                    "
                                                                />
                                                            </span>
                                                            <span
                                                                :style="
                                                                    `${
                                                                        question.goodAnswers.includes('D')
                                                                            ? ''
                                                                            : 'text-decoration:line-through'
                                                                    }`
                                                                "
                                                            >
                                                                {{ question.answerD }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                                <template
                                    v-if="
                                        item.type == 'video' ||
                                            item.type == 'image' ||
                                            item.type == 'pdf' ||
                                            (item.type != null && item.type.includes('interactive'))
                                    "
                                >
                                    <div class="d-flex flex-column align-items-center">
                                        <video
                                            v-if="item.type == 'video'"
                                            preload="auto"
                                            width="50%"
                                            controls
                                            controlslist="nodownload noremoteplayback"
                                            disablePictureInPicture
                                            :key="item.fileUrl"
                                        >
                                            <source :src="item.fileUrl" />
                                            Votre navigateur ne supporte pas les vidéos HTML5. Merci ne le mettre à jour ou d'en changer.
                                        </video>
                                        <img v-else-if="item.type == 'image'" class="fit-picture" :src="item.fileUrl" width="30%" />
                                        <div v-else-if="item.type == 'video_interactive'">
                                            <iframe
                                                :src="
                                                    item.fileUrl
                                                        .split('.')
                                                        .slice(0, -1)
                                                        .join('.') + '/index.html'
                                                "
                                                frameborder="0"
                                            >
                                            </iframe>
                                        </div>
                                        <div v-else-if="item.type == 'question_interactive'">
                                            <iframe
                                                :src="
                                                    item.interactive_item.media_url
                                                        .split('.')
                                                        .slice(0, -1)
                                                        .join('.') +
                                                        '/index.html?question=' +
                                                        item.interactive_details.id +
                                                        '&mode=0'
                                                "
                                                frameborder="0"
                                            >
                                            </iframe>
                                        </div>
                                        <div v-else-if="item.type == 'pdf'">
                                            <!--                                            {{item}}-->
                                            <iframe :src="item.fileUrl" frameborder="0"> </iframe>
                                        </div>
                                        <h3 v-else>Aucun media</h3>
                                        <p>{{ item.description }}</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    @drop="addItemToComposition($event)"
                    @dragover.prevent
                    @dragenter.prevent
                    style="position:absolute;top:0;right:0;opacity:0.4;width: 80%; height: 100%;z-index:2"
                    class="bg-primary d-flex justify-content-around"
                    v-if="onDragItem"
                >
                    <div class="d-flex flex-column text-center align-self-center">
                        <span class="bg-light">
                            <inline-svg height="50%" width="50%" class="m-0 p-0" src="/media/svg/icons/Files/import.svg" />
                        </span>
                        <p class="text-secondary h5 mt-1">Ajouter à la composition</p>
                    </div>
                </div>
            </div>
            <b-modal @hidden="resetInputs()" id="modal-lesson-items-form" size="lg" ref="modal" @ok="validForm">
                <form ref="form" @submit.stop.prevent="addItem">
                    <div v-if="itemForm.type == 'video' || itemForm.type == 'image' || itemForm.type == 'pdf'">
                        <b-form-group
                            label="Titre :"
                            label-for="title-input"
                            invalid-feedback="Veuillez saisir un titre"
                            :state="statesItemForm.title"
                        >
                            <b-form-input id="title-input" v-model="itemForm.title" :state="statesItemForm.title" required></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Description :"
                            label-for="description-input"
                            invalid-feedback="Veuillez saisir une description"
                            :state="statesItemForm.description"
                        >
                            <b-form-textarea
                                id="description-input"
                                v-model="itemForm.description"
                                rows="3"
                                max-rows="6"
                                :state="statesItemForm.description"
                                required
                            >
                            </b-form-textarea>
                        </b-form-group>
                    </div>
                    <div v-if="itemForm.type == 'video' || itemForm.type == 'image' || itemForm.type == 'pdf'">
                        <!-- Pour gérer la state, utiliser :state="Boolean(itemForm.file)" -->
                        <b-form-group
                            label="Fichier :"
                            label-for="item-form-file-input"
                            :invalid-feedback="
                                `${!itemForm.file ? 'Veuillez choisir un fichier' : 'Veuillez choisir un format de fichier valide'}`
                            "
                            :state="statesItemForm.file"
                        >
                            <b-form-file
                                id="item-form-file-input"
                                :accept="
                                    itemForm.type == 'image'
                                        ? 'image/png,image/jpeg'
                                        : itemForm.type === 'pdf'
                                        ? 'application/pdf'
                                        : 'video/mp4'
                                "
                                :state="statesItemForm.file"
                                v-model="itemForm.file"
                                browse-text="Parcourir"
                                placeholder="Choisissez un fichier ou déposez-le ici"
                                drop-placeholder="Deposer le fichier"
                            />
                            <b-form-text id="input-live-help" v-if="itemForm.type == 'image'">
                                Formats acceptés : png, jpg.
                            </b-form-text>
                            <b-form-text id="input-live-help" v-else>
                                Format accepté :
                                {{ itemForm.type === 'video' ? 'mp4' : 'zip' }}.
                            </b-form-text>
                            <div class="mt-3 ml-2 font-italic text-muted">
                                Nom du fichier selectionné :
                                {{ itemForm.file ? itemForm.file.name : '' }}
                            </div>
                        </b-form-group>
                    </div>
                    <div v-if="itemForm.type == 'video_interactive'">
                        <b-form-group
                            label="Titre"
                            label-for="title-input"
                            invalid-feedback="Veuillez saisir un nom"
                            :state="statesItemForm.title"
                        >
                            <b-form-input id="title-input" v-model="itemForm.title" :state="statesItemForm.title" required></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Description"
                            label-for="description-input"
                            invalid-feedback="Veuillez saisir une description"
                            :state="statesItemForm.description"
                        >
                            <b-form-textarea
                                id="description-input"
                                v-model="itemForm.description"
                                rows="3"
                                max-rows="6"
                                :state="statesItemForm.description"
                                required
                            >
                            </b-form-textarea>
                        </b-form-group>
                    </div>
                    <div v-if="itemForm.type == 'video_interactive'">
                        <!-- Pour gérer la state, utiliser :state="Boolean(itemForm.file)" -->
                        <b-form-group
                            label="Fichier"
                            label-for="item-form-file-input"
                            :invalid-feedback="
                                `${!itemForm.file ? 'Veuillez choisir un fichier' : 'Veuillez choisir un format de fichier valide'}`
                            "
                            :state="statesItemForm.file"
                        >
                            <b-form-file
                                id="item-form-file-input"
                                accept=".zip"
                                :state="statesItemForm.file"
                                v-model="itemForm.file"
                                browse-text="Parcourir"
                                placeholder="Choisissez un fichier ou déposez-le ici"
                                drop-placeholder="Deposer le fichier"
                            />
                            <b-form-text id="input-live-help">Format accepté : zip.</b-form-text>
                            <div class="mt-3 ml-2 font-italic text-muted">
                                Nom du fichier selectionné :
                                {{ itemForm.file ? itemForm.file.name : '' }}
                            </div>
                        </b-form-group>
                    </div>
                    <div v-if="itemForm.type === 'question_interactive' && update === false">
                        <b-form-group
                            label="Type de question interactive"
                            label-for="type-input"
                            invalid-feedback="Veuillez choisir un type de question"
                            :state="statesItemForm.type"
                        >
                            <b-form-select v-model="selected_question_interactive" @input="consigneSelect()" required>
                                <b-form-select-option :value="null">Sélectionnez un type d'intéraction </b-form-select-option>
                                <b-form-select-option
                                    v-for="question_interactive in interactiveItems"
                                    :key="question_interactive.id"
                                    :value="question_interactive"
                                    >{{ question_interactive.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div v-if="itemForm.type === 'question_interactive' && (selected_question_interactive || update === true)">
                        <b-form-group
                            label="Titre"
                            label-for="interactive-title-input"
                            invalid-feedback="Veuillez saisir un titre"
                            :state="statesItemForm.interactive.title"
                        >
                            <b-form-input
                                id="interactive-title-input"
                                v-model="itemForm.interactive.title"
                                :state="statesItemForm.interactive.title"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            v-if="
                                (selected_question_interactive && selected_question_interactive.id !== 5) ||
                                    (update === true && interactive_id !== 5)
                            "
                            label="Question"
                            label-for="interactive-question-input"
                            invalid-feedback="Veuillez saisir une question"
                            :state="statesItemForm.interactive.question"
                        >
                            <b-form-input
                                id="interactive-question-input"
                                v-model="itemForm.interactive.question"
                                :state="statesItemForm.interactive.question"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Consigne"
                            label-for="interactive-consigne-input"
                            invalid-feedback="Veuillez saisir une consigne"
                        >
                            <b-form-textarea rows="1" max-rows="2" id="interactive-consigne-input" v-model="itemForm.interactive.consigne">
                            </b-form-textarea>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox-group
                                v-model="itemForm.interactive.explication"
                                :options="{ displayExplication: 'Afficher l\'explication' }"
                                switches
                                @input="resetExplications()"
                            ></b-form-checkbox-group>
                        </b-form-group>
                        <div v-if="itemForm.interactive.explication && itemForm.interactive.explication[0] === 'displayExplication'">
                            <b-form-group
                                label="Texte d'explication"
                                label-for="interactive-explication-text-input"
                                invalid-feedback="Veuillez saisir une explication de maximum 7 lignes ainsi que 90 caractères par ligne maximum"
                                :state="statesItemForm.interactive.explicationTextRows"
                            >
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText1"
                                    placeholder="Entrez la ligne 1 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText2"
                                    placeholder="Entrez la ligne 2 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText3"
                                    placeholder="Entrez la ligne 3 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText4"
                                    placeholder="Entrez la ligne 4 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText5"
                                    placeholder="Entrez la ligne 5 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText6"
                                    placeholder="Entrez la ligne 6 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                                <b-form-input
                                    v-model="itemForm.interactive.explicationText7"
                                    placeholder="Entrez la ligne 7 de l'explication"
                                    type="text"
                                    class="mt-3"
                                    maxlength="90"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                label="Image d'explication"
                                label-for="interactive-explication-image-input"
                                :invalid-feedback="
                                    `${
                                        !itemForm.interactive.explicationImage
                                            ? 'Veuillez choisir un fichier'
                                            : 'Veuillez choisir un format de fichier valide'
                                    }`
                                "
                                :state="statesItemForm.interactive.explicationImage"
                            >
                                <b-form-file
                                    id="interactive-explication-image-input"
                                    accept="image/png,image/jpeg"
                                    :state="statesItemForm.interactive.explicationImage"
                                    v-model="itemForm.interactive.explicationImage"
                                    browse-text="Parcourir"
                                    :placeholder="itemForm.interactive.explicationImage_placeholder"
                                    drop-placeholder="Deposer l'image"
                                />
                                <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                <div class="mt-3 ml-2 font-italic text-muted">
                                    Nom de l'image selectionnée :
                                    {{ itemForm.interactive.explicationImage ? itemForm.interactive.explicationImage.name : '' }}
                                </div>
                            </b-form-group>
                        </div>
                        <hr />
                    </div>
                    <!--                    Images intéractives-->
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 1) ||
                                (update === true && interactive_id === 1)
                        "
                    >
                        <b-form-group
                            label="Image"
                            label-for="interactive-file-input"
                            :invalid-feedback="
                                `${
                                    !itemForm.interactive.file
                                        ? 'Veuillez choisir un fichier'
                                        : 'Veuillez choisir un format de fichier valide'
                                }`
                            "
                            :state="statesItemForm.interactive.file"
                        >
                            <b-form-file
                                id="interactive-file-input"
                                accept="image/png,image/jpeg"
                                :state="statesItemForm.interactive.file"
                                v-model="itemForm.interactive.file"
                                browse-text="Parcourir"
                                placeholder="Choisissez une image ou déposez-la ici"
                                drop-placeholder="Deposer l'image"
                            />
                            <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                            <div class="mt-3 ml-2 font-italic text-muted">
                                Nom de l'image selectionnée :
                                {{ itemForm.interactive.file ? itemForm.interactive.file.name : '' }}
                            </div>
                        </b-form-group>

                        <b-form-group
                            label="Point 1"
                            label-for="interactive-point1-input"
                            invalid-feedback="Veuillez saisir les informations du point 1"
                        >
                            <b-form-input
                                id="interactive-label1-input"
                                class="mb-4"
                                placeholder="Description"
                                :state="statesItemForm.interactive.point1"
                                v-model="itemForm.interactive.label1"
                                required
                            ></b-form-input>
                            <div class="d-flex flex-row justify-content-between">
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="x"
                                    id="interactive-x1-input"
                                    :state="statesItemForm.interactive.point1"
                                    v-model="itemForm.interactive.x1"
                                    required
                                ></b-form-input>
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="y"
                                    id="interactive-y1-input"
                                    :state="statesItemForm.interactive.point1"
                                    v-model="itemForm.interactive.y1"
                                    required
                                ></b-form-input>
                            </div>
                        </b-form-group>

                        <b-form-group
                            label="Point 2"
                            label-for="interactive-point2-input"
                            invalid-feedback="Veuillez saisir les informations du point 2"
                        >
                            <b-form-input
                                id="interactive-label2-input"
                                class="mb-4"
                                placeholder="Description"
                                :state="statesItemForm.interactive.point2"
                                v-model="itemForm.interactive.label2"
                                required
                            >
                            </b-form-input>
                            <div class="d-flex flex-row justify-content-between">
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="x"
                                    id="interactive-x2-input"
                                    :state="statesItemForm.interactive.point2"
                                    v-model="itemForm.interactive.x2"
                                    required
                                >
                                </b-form-input>
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="y"
                                    id="interactive-y2-input"
                                    :state="statesItemForm.interactive.point2"
                                    v-model="itemForm.interactive.y2"
                                    required
                                >
                                </b-form-input>
                            </div>
                        </b-form-group>

                        <b-form-group
                            label="Point 3 (Optionnel)"
                            label-for="interactive-point3-input"
                            invalid-feedback="Veuillez saisir les informations du point 3"
                            :state="itemForm.interactive.point3"
                        >
                            <b-form-input
                                id="interactive-label3-input"
                                class="mb-4"
                                placeholder="Description"
                                v-model="itemForm.interactive.label3"
                            ></b-form-input>
                            <div class="d-flex flex-row justify-content-between">
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="x"
                                    id="interactive-x3-input"
                                    v-model="itemForm.interactive.x3"
                                ></b-form-input>
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="y"
                                    id="interactive-y3-input"
                                    v-model="itemForm.interactive.y3"
                                ></b-form-input>
                            </div>
                        </b-form-group>

                        <b-form-group
                            label="Point 4 (Optionnel)"
                            label-for="interactive-point4-input"
                            invalid-feedback="Veuillez saisir les informations du point 4"
                            :state="itemForm.interactive.point4"
                        >
                            <b-form-input
                                id="interactive-label4-input"
                                class="mb-4"
                                placeholder="Description"
                                v-model="itemForm.interactive.label4"
                            ></b-form-input>
                            <div class="d-flex flex-row justify-content-between">
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="x"
                                    id="interactive-x4-input"
                                    v-model="itemForm.interactive.x4"
                                ></b-form-input>
                                <b-form-input
                                    style="width: 45%"
                                    type="number"
                                    placeholder="y"
                                    id="interactive-y4-input"
                                    v-model="itemForm.interactive.y4"
                                ></b-form-input>
                            </div>
                        </b-form-group>
                    </template>
                    <!--                    Cursors-->
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 2) ||
                                (update === true && interactive_id === 2)
                        "
                    >
                        <b-form-group
                            label="Image"
                            label-for="interactive-file-input"
                            :invalid-feedback="
                                `${
                                    !itemForm.interactive.file
                                        ? 'Veuillez choisir un fichier'
                                        : 'Veuillez choisir un format de fichier valide'
                                }`
                            "
                            :state="statesItemForm.interactive.file"
                        >
                            <b-form-file
                                id="interactive-file-input"
                                accept="image/png,image/jpeg"
                                :state="statesItemForm.interactive.file"
                                v-model="itemForm.interactive.file"
                                browse-text="Parcourir"
                                placeholder="Choisissez une image ou déposez-la ici"
                                drop-placeholder="Deposer l'image"
                            />
                            <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                            <div class="mt-3 ml-2 font-italic text-muted">
                                Nom de l'image selectionnée :
                                {{ itemForm.interactive.file ? itemForm.interactive.file.name : '' }}
                            </div>
                        </b-form-group>

                        <b-form-group
                            label="Propositions*"
                            label-for="interactive-elt-input"
                            invalid-feedback="Veuillez saisir les propositions"
                        >
                            <div class="d-flex flex-row align-items-center mb-5">
                                <b-form-radio
                                    v-model="itemForm.interactive.b_rep"
                                    :state="statesItemForm.interactive.b_rep"
                                    value="1"
                                ></b-form-radio>
                                <b-form-input
                                    id="interactive-question-input"
                                    v-model="itemForm.interactive.elt_1"
                                    :state="statesItemForm.interactive.elt_1"
                                    placeholder="Réponse 1*"
                                    required
                                >
                                </b-form-input>
                            </div>
                            <div class="d-flex flex-row align-items-center mb-5">
                                <b-form-radio
                                    v-model="itemForm.interactive.b_rep"
                                    :state="statesItemForm.interactive.b_rep"
                                    value="2"
                                ></b-form-radio>
                                <b-form-input
                                    id="interactive-question-input"
                                    v-model="itemForm.interactive.elt_2"
                                    :state="statesItemForm.interactive.elt_2"
                                    placeholder="Réponse 2*"
                                    required
                                >
                                </b-form-input>
                            </div>
                            <div class="d-flex flex-row align-items-center mb-5">
                                <b-form-radio
                                    v-model="itemForm.interactive.b_rep"
                                    :state="statesItemForm.interactive.b_rep"
                                    value="3"
                                ></b-form-radio>
                                <b-form-input
                                    id="interactive-question-input"
                                    v-model="itemForm.interactive.elt_3"
                                    :state="statesItemForm.interactive.elt_3"
                                    placeholder="Réponse 3*"
                                    required
                                >
                                </b-form-input>
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <b-form-radio
                                    v-model="itemForm.interactive.b_rep"
                                    :state="statesItemForm.interactive.b_rep"
                                    value="4"
                                ></b-form-radio>
                                <b-form-input
                                    id="interactive-question-input"
                                    v-model="itemForm.interactive.elt_4"
                                    :state="statesItemForm.interactive.elt_4"
                                    placeholder="Réponse 4*"
                                    required
                                >
                                </b-form-input>
                            </div>
                        </b-form-group>
                    </template>
                    <!--                    QCM Images-->
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 3) ||
                                (update === true && interactive_id === 3)
                        "
                    >
                        <div class="d-flex align-items-center mb-8">
                            <b-form-checkbox
                                id="checkbox-qcm-image1"
                                name="checkbox-qcm-image1"
                                v-model="itemForm.interactive.qcm_checkbox1"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>
                            <b-form-group
                                class="mb-0 w-100"
                                label="Image 1"
                                label-for="interactive-qcm-image-1-input"
                                :invalid-feedback="
                                    `${
                                        !itemForm.interactive.qcm_image1
                                            ? 'Veuillez choisir un fichier'
                                            : 'Veuillez choisir un format de fichier valide'
                                    }`
                                "
                                :state="statesItemForm.interactive.qcm_image1"
                            >
                                <b-form-file
                                    id="interactive-qcm-image-1-input"
                                    accept="image/png,image/jpeg"
                                    :state="statesItemForm.interactive.qcm_image1"
                                    v-model="itemForm.interactive.qcm_image1"
                                    browse-text="Parcourir"
                                    :placeholder="itemForm.interactive.qcm_image1_placeholder"
                                    drop-placeholder="Deposer l'image"
                                />
                                <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                            </b-form-group>
                        </div>
                        <div class="d-flex align-items-center mb-8">
                            <b-form-checkbox
                                id="checkbox-qcm-image2"
                                name="checkbox-qcm-image2"
                                v-model="itemForm.interactive.qcm_checkbox2"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>
                            <b-form-group
                                class="mb-0 w-100"
                                label="Image 2"
                                label-for="interactive-qcm-image-2-input"
                                :invalid-feedback="
                                    `${
                                        !itemForm.interactive.qcm_image2
                                            ? 'Veuillez choisir un fichier'
                                            : 'Veuillez choisir un format de fichier valide'
                                    }`
                                "
                                :state="statesItemForm.interactive.qcm_image2"
                            >
                                <b-form-file
                                    id="interactive-qcm-image-2-input"
                                    accept="image/png,image/jpeg"
                                    :state="statesItemForm.interactive.qcm_image2"
                                    v-model="itemForm.interactive.qcm_image2"
                                    browse-text="Parcourir"
                                    :placeholder="itemForm.interactive.qcm_image2_placeholder"
                                    drop-placeholder="Deposer l'image"
                                />
                                <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                            </b-form-group>
                        </div>
                        <div class="d-flex align-items-center mb-8">
                            <b-form-checkbox
                                id="checkbox-qcm-image3"
                                name="checkbox-qcm-image3"
                                v-model="itemForm.interactive.qcm_checkbox3"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>
                            <b-form-group
                                class="mb-0 w-100"
                                label="Image 3"
                                label-for="interactive-qcm-image-3-input"
                                :invalid-feedback="
                                    `${
                                        !itemForm.interactive.qcm_image3
                                            ? 'Veuillez choisir un fichier'
                                            : 'Veuillez choisir un format de fichier valide'
                                    }`
                                "
                                :state="statesItemForm.interactive.qcm_image3"
                            >
                                <b-form-file
                                    id="interactive-qcm-image-3-input"
                                    accept="image/png,image/jpeg"
                                    :state="statesItemForm.interactive.qcm_image3"
                                    v-model="itemForm.interactive.qcm_image3"
                                    browse-text="Parcourir"
                                    :placeholder="itemForm.interactive.qcm_image3_placeholder"
                                    drop-placeholder="Deposer l'image"
                                />
                                <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                            </b-form-group>
                        </div>
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                id="checkbox-qcm-image4"
                                name="checkbox-qcm-image4"
                                v-model="itemForm.interactive.qcm_checkbox4"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>
                            <b-form-group
                                class="mb-0 w-100"
                                label="Image 4"
                                label-for="interactive-qcm-image-4-input"
                                :invalid-feedback="
                                    `${
                                        !itemForm.interactive.qcm_image4
                                            ? 'Veuillez choisir un fichier'
                                            : 'Veuillez choisir un format de fichier valide'
                                    }`
                                "
                                :state="statesItemForm.interactive.qcm_image4"
                            >
                                <b-form-file
                                    id="interactive-qcm-image-4-input"
                                    accept="image/png,image/jpeg"
                                    :state="statesItemForm.interactive.qcm_image4"
                                    v-model="itemForm.interactive.qcm_image4"
                                    browse-text="Parcourir"
                                    :placeholder="itemForm.interactive.qcm_image4_placeholder"
                                    drop-placeholder="Deposer l'image"
                                />
                                <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                            </b-form-group>
                        </div>
                    </template>
                    <!--                    Relier les points-->
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 4) ||
                                (update === true && interactive_id === 4)
                        "
                    >
                        <div class="d-flex">
                            <div class="d-flex justify-content-start flex-column w-50 mr-2">
                                <div class="d-flex mb-4">
                                    <b-form-checkbox
                                        class="mx-3"
                                        v-model="itemForm.interactive.checked_left"
                                        name="check-button"
                                        switch
                                        @input="resetSwitch('left')"
                                    ></b-form-checkbox>
                                    <p class="m-0">Utiliser des images pour les proposition</p>
                                </div>
                                <div v-if="itemForm.interactive.checked_left === false" :key="ForceReRender">
                                    <b-form-group class="mb-4" label="Proposition 1*" label-for="interactive-question-1-input">
                                        <b-form-input
                                            id="interactive-question-1-input"
                                            v-model="itemForm.interactive.elt_1"
                                            :state="statesItemForm.interactive.elt_1"
                                            placeholder="Proposition 1*"
                                            required
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-4" label="Proposition 2*" label-for="interactive-question-2-input">
                                        <b-form-input
                                            id="interactive-question-2-input"
                                            v-model="itemForm.interactive.elt_2"
                                            :state="statesItemForm.interactive.elt_2"
                                            placeholder="Proposition 2*"
                                            required
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-4" label="Proposition 3" label-for="interactive-question-3-input">
                                        <b-form-input
                                            id="interactive-question-3-input"
                                            v-model="itemForm.interactive.elt_3"
                                            :state="statesItemForm.interactive.elt_3"
                                            placeholder="Proposition 3"
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-0 w-100" label="Proposition 4" label-for="interactive-question-4-input">
                                        <b-form-input
                                            id="interactive-question-4-input"
                                            v-model="itemForm.interactive.elt_4"
                                            :state="statesItemForm.interactive.elt_4"
                                            placeholder="Proposition 4"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div v-else :key="ForceReRender">
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Proposition 1"
                                        label-for="interactive-proposition-1-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_1
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_1"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-1-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_1"
                                            v-model="itemForm.interactive.elt_1"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_1_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Proposition 2"
                                        label-for="interactive-proposition-2-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_2
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_2"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-2-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_2"
                                            v-model="itemForm.interactive.elt_2"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_2_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Proposition 3"
                                        label-for="interactive-proposition-3-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_3
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_3"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-3-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_3"
                                            v-model="itemForm.interactive.elt_3"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_3_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100"
                                        label="Proposition 4"
                                        label-for="interactive-proposition-4-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_4
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_4"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-4-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_4"
                                            v-model="itemForm.interactive.elt_4"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_4_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start flex-column w-50 ml-2">
                                <div class="d-flex mb-4">
                                    <b-form-checkbox
                                        class="mx-3"
                                        v-model="itemForm.interactive.checked_right"
                                        name="check-button"
                                        switch
                                        @input="resetSwitch('right')"
                                    ></b-form-checkbox>
                                    <p class="m-0">Utiliser des images pour les réponses</p>
                                </div>
                                <div v-if="itemForm.interactive.checked_right === false" :key="ForceReRender">
                                    <b-form-group class="mb-4" label="Réponse 1*" label-for="interactive-reponse-1-input">
                                        <b-form-input
                                            id="interactive-reponse-1-input"
                                            v-model="itemForm.interactive.rep_1"
                                            :state="statesItemForm.interactive.rep_1"
                                            placeholder="Réponse 1*"
                                            required
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-4" label="Réponse 2*" label-for="interactive-reponse-2-input">
                                        <b-form-input
                                            id="interactive-reponse-2-input"
                                            v-model="itemForm.interactive.rep_2"
                                            :state="statesItemForm.interactive.rep_2"
                                            placeholder="Réponse 2*"
                                            required
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-4" label="Réponse 3" label-for="interactive-reponse-3-input">
                                        <b-form-input
                                            id="interactive-reponse-3-input"
                                            v-model="itemForm.interactive.rep_3"
                                            :state="statesItemForm.interactive.rep_3"
                                            placeholder="Réponse 3"
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group label="Réponse 4" label-for="interactive-reponse-4-input">
                                        <b-form-input
                                            id="interactive-reponse-4-input"
                                            v-model="itemForm.interactive.rep_4"
                                            :state="statesItemForm.interactive.rep_4"
                                            placeholder="Réponse 4"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div v-else :key="ForceReRender">
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Réponse 1"
                                        label-for="interactive-reponse-1-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.rep_1
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.rep_1"
                                    >
                                        <b-form-file
                                            id="interactive-reponse-1-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.rep_1"
                                            v-model="itemForm.interactive.rep_1"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.rep_1_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Réponse 2"
                                        label-for="interactive-reponse-2-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.rep_2
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.rep_2"
                                    >
                                        <b-form-file
                                            id="interactive-reponse-2-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.rep_2"
                                            v-model="itemForm.interactive.rep_2"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.rep_2_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Réponse 3"
                                        label-for="interactive-reponse-3-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.rep_3
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.rep_3"
                                    >
                                        <b-form-file
                                            id="interactive-reponse-3-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.rep_3"
                                            v-model="itemForm.interactive.rep_3"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.rep_3_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100"
                                        label="Réponse 4"
                                        label-for="interactive-reponse-4-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.rep_4
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.rep_4"
                                    >
                                        <b-form-file
                                            id="interactive-reponse-4-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.rep_4"
                                            v-model="itemForm.interactive.rep_4"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.rep_4_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!--                    Texte a trou-->
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 5) ||
                                (update === true && interactive_id === 5)
                        "
                    >
                        <b-form-group label="Première partie de la phrase">
                            <b-form-input
                                id="interactive-part1-input"
                                v-model="itemForm.interactive.elt_1"
                                :state="statesItemForm.interactive.elt_1"
                                placeholder="Première partie de la phrase"
                            >
                            </b-form-input>
                        </b-form-group>
                        <b-form-group label="Texte à compléter (bonne réponse)">
                            <b-form-input
                                id="interactive-rep1-input"
                                v-model="itemForm.interactive.rep_1"
                                :state="statesItemForm.interactive.rep_1"
                                placeholder="Texte à compléter (bonne réponse)"
                                :maxlength="itemForm.interactive.max_input"
                            >
                            </b-form-input>
                            <b-form-text>{{ itemForm.interactive.max_input }} caractères maximum.</b-form-text>
                        </b-form-group>
                        <b-form-group label="Deuxième partie de la phrase" class="mt-4">
                            <b-form-input
                                id="interactive-part2-input"
                                v-model="itemForm.interactive.elt_2"
                                :state="statesItemForm.interactive.elt_2"
                                placeholder="Deuxième partie de la phrase"
                            >
                            </b-form-input>
                        </b-form-group>

                        <p>Autres propositions (mauvaises réponses)</p>
                        <b-form-group label="Réponse 2" class="mt-4">
                            <b-form-input
                                id="interactive-rep2-input"
                                v-model="itemForm.interactive.rep_2"
                                :state="statesItemForm.interactive.rep_2"
                                placeholder="Réponse 2"
                                :maxlength="itemForm.interactive.max_input"
                            >
                            </b-form-input>
                            <b-form-text>{{ itemForm.interactive.max_input }} caractères maximum.</b-form-text>
                        </b-form-group>
                        <b-form-group label="Réponse 3" class="mt-4">
                            <b-form-input
                                id="interactive-rep3-input"
                                v-model="itemForm.interactive.rep_3"
                                :state="statesItemForm.interactive.rep_3"
                                placeholder="Réponse 3"
                                :maxlength="itemForm.interactive.max_input"
                            >
                            </b-form-input>
                            <b-form-text>{{ itemForm.interactive.max_input }} caractères maximum.</b-form-text>
                        </b-form-group>
                    </template>
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 6) ||
                                (update === true && interactive_id === 6)
                        "
                    >
                        <p>Propositions</p>
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                id="checkbox-qcm1"
                                name="checkbox-qcm1"
                                v-model="itemForm.interactive.qcm_checkbox1"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>

                            <b-form-input
                                id="interactive-question-input"
                                placeholder="Proposition 1"
                                v-model="itemForm.interactive.elt_1"
                                :state="statesItemForm.interactive.elt_1"
                                required
                            >
                            </b-form-input>
                        </div>
                        <div class="d-flex align-items-center mt-4">
                            <b-form-checkbox
                                id="checkbox-qcm2"
                                name="checkbox-qcm2"
                                v-model="itemForm.interactive.qcm_checkbox2"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>

                            <b-form-input
                                id="interactive-question-input"
                                placeholder="Proposition 2"
                                v-model="itemForm.interactive.elt_2"
                                :state="statesItemForm.interactive.elt_2"
                                required
                            >
                            </b-form-input>
                        </div>
                        <div class="d-flex align-items-center mt-4">
                            <b-form-checkbox
                                id="checkbox-qcm3"
                                name="checkbox-qcm3"
                                v-model="itemForm.interactive.qcm_checkbox3"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>

                            <b-form-input
                                id="interactive-question-input"
                                placeholder="Proposition 3"
                                v-model="itemForm.interactive.elt_3"
                                :state="statesItemForm.interactive.elt_3"
                            >
                            </b-form-input>
                        </div>
                        <div class="d-flex align-items-center mt-4">
                            <b-form-checkbox
                                id="checkbox-qcm4"
                                name="checkbox-qcm4"
                                v-model="itemForm.interactive.qcm_checkbox4"
                                value="1"
                                unchecked-value="0"
                                :state="statesItemForm.interactive.qcm_checkbox"
                            >
                            </b-form-checkbox>

                            <b-form-input
                                id="interactive-question-input"
                                placeholder="Proposition 4"
                                v-model="itemForm.interactive.elt_4"
                                :state="statesItemForm.interactive.elt_4"
                            >
                            </b-form-input>
                        </div>
                    </template>
                    <!--                    ranger les images-->
                    <template
                        v-if="
                            (selected_question_interactive && selected_question_interactive.id === 7) ||
                                (update === true && interactive_id === 7)
                        "
                    >
                        <div class="d-flex">
                            <div class="d-flex justify-content-start flex-column w-50 mr-2">
                                <div class="d-flex mb-4">
                                    <b-form-checkbox
                                        class="mr-2"
                                        v-model="itemForm.interactive.checked_left"
                                        name="check-button"
                                        switch
                                        @input="resetSwitch('left')"
                                    ></b-form-checkbox>
                                    <p class="m-0">Utiliser des images pour les réponses</p>
                                </div>
                                <div v-if="itemForm.interactive.checked_left === false" :key="ForceReRender">
                                    <b-form-group class="mb-4" label="Proposition 1*" label-for="interactive-question-1-input">
                                        <b-form-input
                                            id="interactive-question-1-input"
                                            v-model="itemForm.interactive.elt_1"
                                            :state="statesItemForm.interactive.elt_1"
                                            placeholder="Proposition 1*"
                                            required
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-4" label="Proposition 2*" label-for="interactive-question-2-input">
                                        <b-form-input
                                            id="interactive-question-2-input"
                                            v-model="itemForm.interactive.elt_2"
                                            :state="statesItemForm.interactive.elt_2"
                                            placeholder="Proposition 2*"
                                            required
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-4" label="Proposition 3" label-for="interactive-question-3-input">
                                        <b-form-input
                                            id="interactive-question-3-input"
                                            v-model="itemForm.interactive.elt_3"
                                            :state="statesItemForm.interactive.elt_3"
                                            placeholder="Proposition 3"
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                    <b-form-group class="mb-0" label="Proposition 4" label-for="interactive-question-4-input">
                                        <b-form-input
                                            id="interactive-question-4-input"
                                            v-model="itemForm.interactive.elt_4"
                                            :state="statesItemForm.interactive.elt_4"
                                            placeholder="Proposition 4"
                                        >
                                        </b-form-input>
                                        <b-form-text id="input-live-help">&nbsp;</b-form-text>
                                    </b-form-group>
                                </div>
                                <div v-else :key="ForceReRender">
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Propositon 1"
                                        label-for="interactive-proposition-1-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_1
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_1"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-1-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_1"
                                            v-model="itemForm.interactive.elt_1"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_1_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Proposition 2"
                                        label-for="interactive-proposition-2-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_2
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_2"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-2-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_2"
                                            v-model="itemForm.interactive.elt_2"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_2_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100 mb-4"
                                        label="Proposition 3"
                                        label-for="interactive-proposition-3-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_3
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_3"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-3-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_3"
                                            v-model="itemForm.interactive.elt_3"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_3_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        class="mb-0 w-100"
                                        label="Proposition 4"
                                        label-for="interactive-proposition-4-input"
                                        :invalid-feedback="
                                            `${
                                                !itemForm.interactive.elt_4
                                                    ? 'Veuillez choisir un fichier'
                                                    : 'Veuillez choisir un format de fichier valide'
                                            }`
                                        "
                                        :state="statesItemForm.interactive.elt_4"
                                    >
                                        <b-form-file
                                            id="interactive-proposition-4-input"
                                            accept="image/png,image/jpeg"
                                            :state="statesItemForm.interactive.elt_4"
                                            v-model="itemForm.interactive.elt_4"
                                            browse-text="Parcourir"
                                            :placeholder="itemForm.interactive.elt_4_placeholder"
                                            drop-placeholder="Deposer l'image"
                                        />
                                        <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                    </b-form-group>
                                </div>
                            </div>

                            <!--                            partie droite-->
                            <div class="d-flex justify-content-start flex-column w-50 mr-2 mt-10">
                                <b-form-group
                                    class="mb-0 w-100 mb-4"
                                    label="Réponse 1"
                                    label-for="interactive-reponse-4-input"
                                    :invalid-feedback="
                                        `${
                                            !itemForm.interactive.rep_1
                                                ? 'Veuillez choisir un fichier'
                                                : 'Veuillez choisir un format de fichier valide'
                                        }`
                                    "
                                    :state="statesItemForm.interactive.rep_1"
                                >
                                    <b-form-file
                                        id="interactive-reponse-1-input"
                                        accept="image/png,image/jpeg"
                                        :state="statesItemForm.interactive.rep_1"
                                        v-model="itemForm.interactive.rep_1"
                                        browse-text="Parcourir"
                                        :placeholder="itemForm.interactive.rep_1_placeholder"
                                        drop-placeholder="Deposer l'image"
                                    />
                                    <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                </b-form-group>

                                <b-form-group
                                    class="mb-0 w-100 mb-4"
                                    label="Réponse 2"
                                    label-for="interactive-reponse-2-input"
                                    :invalid-feedback="
                                        `${
                                            !itemForm.interactive.rep_2
                                                ? 'Veuillez choisir un fichier'
                                                : 'Veuillez choisir un format de fichier valide'
                                        }`
                                    "
                                    :state="statesItemForm.interactive.rep_2"
                                >
                                    <b-form-file
                                        id="interactive-reponse-2-input"
                                        accept="image/png,image/jpeg"
                                        :state="statesItemForm.interactive.rep_2"
                                        v-model="itemForm.interactive.rep_2"
                                        browse-text="Parcourir"
                                        :placeholder="itemForm.interactive.rep_2_placeholder"
                                        drop-placeholder="Deposer l'image"
                                    />
                                    <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                </b-form-group>

                                <b-form-group
                                    class="mb-0 w-100 mb-4"
                                    label="Réponse 3"
                                    label-for="interactive-reponse-3-input"
                                    :invalid-feedback="
                                        `${
                                            !itemForm.interactive.rep_3
                                                ? 'Veuillez choisir un fichier'
                                                : 'Veuillez choisir un format de fichier valide'
                                        }`
                                    "
                                    :state="statesItemForm.interactive.rep_3"
                                >
                                    <b-form-file
                                        id="interactive-reponse-3-input"
                                        accept="image/png,image/jpeg"
                                        :state="statesItemForm.interactive.rep_3"
                                        v-model="itemForm.interactive.rep_3"
                                        browse-text="Parcourir"
                                        :placeholder="itemForm.interactive.rep_3_placeholder"
                                        drop-placeholder="Deposer l'image"
                                    />
                                    <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                </b-form-group>

                                <b-form-group
                                    class="mb-0 w-100"
                                    label="Réponse 4"
                                    label-for="interactive-reponse-4-input"
                                    :invalid-feedback="
                                        `${
                                            !itemForm.interactive.rep_4
                                                ? 'Veuillez choisir un fichier'
                                                : 'Veuillez choisir un format de fichier valide'
                                        }`
                                    "
                                    :state="statesItemForm.interactive.rep_4"
                                >
                                    <b-form-file
                                        id="interactive-reponse-4-input"
                                        accept="image/png,image/jpeg"
                                        :state="statesItemForm.interactive.rep_4"
                                        v-model="itemForm.interactive.rep_4"
                                        browse-text="Parcourir"
                                        :placeholder="itemForm.interactive.rep_4_placeholder"
                                        drop-placeholder="Deposer l'image"
                                    />
                                    <b-form-text id="input-live-help">Formats acceptés : png, jpg.</b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                    </template>
                    <questionnaire
                        :showAnswers.sync="showAnswers"
                        :itemForm.sync="itemForm"
                        :statesItemForm.sync="statesItemForm"
                        :inputQuestionItem.sync="inputQuestionItem"
                        :error.sync="error"
                        :deleting="deleting"
                        @resetAnimation="resetAnimation()"
                        @newQuestionForm="newQuestionForm()"
                        @deleteQuestion="deleteQuestion"
                        @previousQuestionForm="previousQuestionForm()"
                        @nextQuestionForm="nextQuestionForm()"
                    />
                </form>
                <template #modal-footer="{ok,cancel}">
                    <div class="w-100" :style="!submit ? 'display:block' : 'display:none'">
                        <div
                            v-if="
                                (itemForm.type == 'question' && showAnswers) ||
                                    (itemForm.type == 'questionnaire' && showAnswers) ||
                                    (itemForm.type == 'end' && showAnswers)
                            "
                            class="float-left"
                        >
                            <b-button variant="primary" size="sm" @click="() => (showAnswers = !showAnswers)">
                                Retour
                            </b-button>
                        </div>
                        <div class="float-right" disabled>
                            <b-button variant="secondary" size="sm" @click="cancel(), (selected_question_interactive = null)"
                                >Annuler
                            </b-button>
                            <b-button
                                v-if="
                                    (itemForm.type == 'question' && !showAnswers) ||
                                        (itemForm.type == 'questionnaire' && !showAnswers) ||
                                        (itemForm.type == 'end' && !showAnswers)
                                "
                                variant="primary"
                                class="mx-1"
                                size="sm"
                                @click="continueForm()"
                            >
                                Continuer
                            </b-button>
                            <b-button v-else variant="primary" class="mx-1" size="sm" @click="ok()">Valider</b-button>
                        </div>
                    </div>
                </template>
            </b-modal>
            <!-- modal supprimer un élément -->
            <b-modal id="delete-modal" header-text-variant="warning" title="Supprimer un élément de la composition">
                <p class="my-4 text-muted">
                    Êtes-vous sûr de vouloir supprimer cet élément ?
                </p>
                <template #modal-footer="{ cancel }">
                    <b-button variant="danger" @click="deleteItem(deleteItemId)"> Supprimer</b-button>
                    <b-button
                        variant="secondary"
                        @click="
                            cancel();
                            deleteItemId = null;
                        "
                    >
                        {{ $t('CONTROLLERS.CANCEL') }}
                    </b-button>
                </template>
            </b-modal>
            <!-- modal supprimer un élément -->
        </div>
    </div>
</template>
<script>
import { LIST_LESSON_ITEMS } from '@/core/services/store/api/lessonitems.service';
import { LIST_LESSON } from '@/core/services/store/api/lesson.service';
import { LIST_INTERACTIVEITEMS } from '@/core/services/store/api/interactive-items.service';
import { mapGetters } from 'vuex';

import ApiService from '@/core/services/api.service';
import moment from 'moment';
import Nl2br from 'vue-nl2br';

export default {
    name: 'lesson-items-editor',
    components: {
        Nl2br,
        questionnaire: () => import('@/components/admin/editorLessonItems/questionnaire.vue')
    },
    mounted() {
        this.$store.dispatch(LIST_INTERACTIVEITEMS);
        if (this.$route.params.id) {
            this.$store.dispatch(LIST_LESSON_ITEMS, this.$route.params.id).then(() => {
                this.initCompostition(this.getterLessonItems);
                if (this.$route.params.item) {
                    this.editItem(this.$route.params.item);
                }
            });
            this.$store.dispatch(LIST_LESSON, this.$route.params.id);
        } else {
            this.$router.push({ name: 'admin/dashboard' });
        }
    },
    computed: {
        ...mapGetters({
            lesson: 'getLesson',
            getterLessonItems: 'getLessonItems',
            interactiveItems: 'getInteractiveItems'
        })
    },
    data() {
        return {
            interactive_id: null,
            update: false,
            deleting: false,
            test: 0,
            end: false,
            submit: false,
            orderItems: 0,
            onDragOrder: null,
            onDragItem: false,
            detailsShowing: null,
            showAnswers: false,
            mooveHoverOrder: null,
            deleteItemId: null,
            items: [
                { type: 'video', title: 'Video', src: '/media/lesson_type/video.jpg' },
                { type: 'video_interactive', title: 'Video Interactive', src: '/media/lesson_type/videos_interactives.jpg' },
                { type: 'image', title: 'Image', src: '/media/lesson_type/image.jpg' },
                { type: 'pdf', title: 'PDF', src: '/media/lesson_type/pdf.jpg' },
                { type: 'question', title: 'Question seule', src: '/media/lesson_type/question.jpg' },
                { type: 'questionnaire', title: 'Questionnaire', src: '/media/lesson_type/questionnaire.jpg' },
                { type: 'question_interactive', title: 'Question Interactive', src: '/media/lesson_type/question_interactive.jpg' }
            ],
            error: {
                state: false,
                title: null,
                errorMessage: null,
                question: null,
                goodAnswers: null,
                answerA: null,
                answerACheckBox: null,
                answerB: null,
                answerBCheckBox: null,
                answerC: null,
                answerCCheckBox: null,
                answerD: null,
                answerDCheckBox: null,
                countGoodAnswers: null,
                messagePush: []
            },
            selected_question_interactive: null,
            lessonItems: [{ order: 0, type: 'start' }],
            itemForm: {
                order: null,
                type: null,
                title: null,
                description: null,
                text: null,
                link: null,
                test: [],
                file: null,
                message: null,
                goal: 10,
                interactive: {
                    title: null,
                    question: null,
                    consigne: null,
                    explication: [],
                    explicationText1: null,
                    explicationText2: null,
                    explicationText3: null,
                    explicationText4: null,
                    explicationText5: null,
                    explicationText6: null,
                    explicationText7: null,
                    explicationImage: null,
                    explicationImage_placeholder: 'Choisissez une image ou déposez-la ici',
                    file: null,
                    label1: null,
                    x1: null,
                    y1: null,
                    label2: null,
                    x2: null,
                    y2: null,
                    label3: null,
                    x3: null,
                    y3: null,
                    label4: null,
                    x4: null,
                    y4: null,
                    elt_1: null,
                    elt_2: null,
                    elt_3: null,
                    elt_4: null,
                    rep_1: null,
                    rep_2: null,
                    rep_3: null,
                    rep_4: null,
                    b_rep: null,
                    qcm_image1: null,
                    qcm_image2: null,
                    qcm_image3: null,
                    qcm_image4: null,
                    qcm_checkbox1: 0,
                    qcm_checkbox2: 0,
                    qcm_checkbox3: 0,
                    qcm_checkbox4: 0,
                    qcm_image1_placeholder: 'Choisissez une image ou déposez-la ici',
                    qcm_image2_placeholder: 'Choisissez une image ou déposez-la ici',
                    qcm_image3_placeholder: 'Choisissez une image ou déposez-la ici',
                    qcm_image4_placeholder: 'Choisissez une image ou déposez-la ici',
                    checked_left: false,
                    checked_right: false,
                    elt_1_placeholder: 'Choisissez une image ou déposez-la ici',
                    elt_2_placeholder: 'Choisissez une image ou déposez-la ici',
                    elt_3_placeholder: 'Choisissez une image ou déposez-la ici',
                    elt_4_placeholder: 'Choisissez une image ou déposez-la ici',
                    rep_1_placeholder: 'Choisissez une image ou déposez-la ici',
                    rep_2_placeholder: 'Choisissez une image ou déposez-la ici',
                    rep_3_placeholder: 'Choisissez une image ou déposez-la ici',
                    rep_4_placeholder: 'Choisissez une image ou déposez-la ici',
                    max_input: 23
                }
            },
            statesItemForm: {
                order: null,
                type: null,
                question: null,
                title: null,
                description: null,
                text: null,
                link: null,
                test: null,
                file: null,
                nextFormId: 1,
                next: false,
                previous: false,
                onFocus: 0,
                interactive: {
                    select: null,
                    title: null,
                    question: null,
                    file: null,
                    point1: null,
                    point2: null,
                    point3: null,
                    point4: null,
                    elt_1: null,
                    elt_2: null,
                    elt_3: null,
                    elt_4: null,
                    rep_1: null,
                    rep_2: null,
                    rep_3: null,
                    rep_4: null,
                    b_rep: null,
                    qcm_image1: null,
                    qcm_image2: null,
                    qcm_image3: null,
                    qcm_image4: null,
                    qcm_checkbox: null,
                    explicationTextRows: null
                }
            },
            inputQuestionItem: {
                question: null,
                answerA: null,
                answerB: null,
                answerC: null,
                answerD: null,
                goodAnswers: []
            },
            ForceReRender: 1
        };
    },
    methods: {
        async deleteQuestion(SelectID, update) {
            this.resetErrors();
            // conditionner cette fonction à si l'on reçois un onFocus ou un id
            if (SelectID && this.itemForm.test.length > 1 && update) {
                this.deleting = true;
                ApiService.delete(`${process.env.VUE_APP_API_URL}/academy/surveyItem/${SelectID}`)
                    .then(() => {
                        for (let i = 0; i < this.itemForm.test.length; i++) {
                            if (this.itemForm.test[i].id === parseInt(SelectID)) {
                                if (this.itemForm.test[i + 1]) {
                                    this.focusQuestionForm(i + 1);
                                } else {
                                    this.previousQuestionForm();
                                }
                                this.statesItemForm.nextFormId--;
                                if (i === this.itemForm.test.length - 1) {
                                    this.itemForm.test.pop();
                                } else {
                                    this.itemForm.test.splice(i, 1);
                                }
                                this.$bvToast.toast(`La question a été supprimée avec succès.`, {
                                    variant: 'success',
                                    title: 'Info'
                                });
                            }
                        }
                        this.deleting = false;
                    })
                    .catch(() => {
                        this.deleting = false;
                        this.$bvToast.toast(
                            `Nous sommes désolés, mais une erreur s'est produite lors de la suppression. \n Merci de réessayer ultérieurement.`,
                            {
                                variant: 'warning',
                                title: 'Erreur'
                            }
                        );
                    });
            } else if (this.itemForm.test.length > 1 || this.statesItemForm.nextFormId > 1) {
                this.$nextTick();
                if (SelectID === 0) {
                    this.itemForm.test.splice(SelectID, 1);
                    this.focusQuestionForm(0);
                } else {
                    this.previousQuestionForm(false);
                    this.itemForm.test.splice(SelectID, 1);
                }
                this.statesItemForm.nextFormId--;
                this.$bvToast.toast(`La question a été supprimée avec succès.`, {
                    variant: 'success',
                    title: 'Info'
                });
            } else if (this.itemForm.test.length <= 1) {
                this.$bvToast.toast(`Il est impossible de supprimer le seul élément du questionnaire.`, {
                    variant: 'warning',
                    title: 'Erreur'
                });
            }
        },
        consigneSelect() {
            if (this.selected_question_interactive) {
                switch (this.selected_question_interactive.id) {
                    // Consigne par défaut pour les items intéractifs
                    // Images Intéractives
                    case 1:
                        this.itemForm.interactive.consigne = 'Cliquez sur les boutons dans le bon ordre.';
                        break;
                    // Curor
                    case 2:
                        this.itemForm.interactive.consigne = "Déplacez le curseur jusqu'à la bonne réponse.";
                        break;
                    // QCM Image
                    case 3:
                        this.itemForm.interactive.consigne = 'Cliquez sur la bonne image puis confirmez.';
                        break;
                    // Relier les réponses
                    case 4:
                        this.itemForm.interactive.consigne =
                            "Cliquez sur deux blocs pour les lier ensemble. En cas d'erreur, cliquez sur un lien pour le supprimer.";
                        break;
                    // Texte a trou
                    case 5:
                        this.itemForm.interactive.consigne = 'Glissez déposez la bonne réponse pour compléter la phrase.';
                        break;
                    // QCM
                    case 6:
                        this.itemForm.interactive.consigne = 'Cliquez sur la bonne réponse puis confirmez.';
                        break;
                    // Ranger Images
                    case 7:
                        this.itemForm.interactive.consigne = 'Glissez et déposez les images sur la bonne zone.';
                        break;
                }
            }
        },
        //////////| RECUPÉRATION D'UNE COMPOSITION EXISTANTE |/////////////
        initCompostition(items) {
            let itemlist = [];
            items.forEach(function(item) {
                let newItem = {
                    id: item.id,
                    created_at: item.created_at,
                    order: item.order,
                    title: '',
                    description: '',
                    text: ''
                };
                if (item.survey_id) {
                    if (item.survey.type == 1) {
                        newItem.type = 'end';
                    } else {
                        newItem.type = 'questionnaire';
                    }
                    newItem.survey_id = item.survey_id;
                    (newItem.message = item.survey.message),
                        (newItem.goal = item.survey.goal),
                        (newItem.description = item.survey.description),
                        (newItem.title = item.survey.title),
                        (newItem.test = []);
                    item.surveyItems.forEach(function(surveyItem) {
                        const question = {
                            id: surveyItem.id,
                            created_at: surveyItem.created_at,
                            question: surveyItem.question,
                            answerA: surveyItem.answer_a,
                            answerB: surveyItem.answer_b,
                            answerC: surveyItem.answer_c,
                            answerD: surveyItem.answer_d,
                            goodAnswers: surveyItem.solution.split(''),
                            message: surveyItem.message
                        };
                        newItem.test.push(question);
                    });
                } else if (item.survey_item_id) {
                    newItem.type = 'question';
                    newItem.survey_item_id = item.survey_item_id;
                    newItem.message = item.surveyItems.message;
                    newItem.title = item.surveyItems.title_question ? item.surveyItems.title_question : item.surveyItems.question;
                    newItem.test = [
                        {
                            id: item.surveyItems.id,
                            created_at: item.surveyItems.created_at,
                            question: item.surveyItems.question,
                            answerA: item.surveyItems.answer_a,
                            answerB: item.surveyItems.answer_b,
                            answerC: item.surveyItems.answer_c,
                            answerD: item.surveyItems.answer_d,
                            goodAnswers: item.surveyItems.solution.split('')
                        }
                    ];
                } else if (item.media_id) {
                    const extension = item.media.url.split('.').pop();
                    if (extension.toUpperCase() == 'JPG' || extension.toUpperCase() == 'PNG') {
                        newItem.type = 'image';
                    } else if (extension.toUpperCase() == 'ZIP') {
                        newItem.type = 'video_interactive';
                    } else if (extension.toUpperCase() == 'PDF') {
                        newItem.type = 'pdf';
                    } else {
                        newItem.type = 'video';
                    }
                    newItem.fileUrl = item.media.url;
                    newItem.title = item.media.name;
                    newItem.description = item.media.description;
                    newItem.media_id = item.media_id;
                } else if (item.interactives_items_id) {
                    newItem.type = 'question_interactive';
                    newItem.title = item.interaction_title;
                    newItem.interactive_item = item.interactives_item;
                    newItem.interactive_details = item.interactives_details;
                }
                itemlist.push(newItem);
            });
            if (itemlist.find(item => item.type == 'end')) {
                this.end = true;
            }
            this.lessonItems = this.lessonItems.concat(itemlist);
            this.orderItems = this.lessonItems.length - 1;
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        ///////////////////////////////////////////////////////////////////
        //////////| DROP DE LA LISTE D'ITEM A LA COMPOSITION |/////////////
        // DRAG D'UN ITEM DANS LA LISTE D'OUTILS ( volet de gauche )
        startDrag(evt, item) {
            if (!this.end) {
                this.onDragItem = true;
            } else {
                this.$bvToast.toast(
                    "Vous ne pouvez plus ajouter d'éléments lorsqu'il y a  un questionnaire final. Décochez le grâce à la couronne pour ajouter de nouveau des éléments",
                    {
                        title: 'Erreur : ',
                        variant: 'warning',
                        solid: true
                    }
                );
            }
            evt.dataTransfer.dropEffect = 'copy';
            evt.dataTransfer.effectAllowed = 'copy';
            evt.dataTransfer.setData('itemtitle', item.title);
            evt.dataTransfer.setData('itemType', item.type);
            evt.dataTransfer.setData('from', 'itemsList');
        },
        // Event exitDrag
        exitDrag() {
            this.onDragItem = false;
            this.mooveHoverOrder = null;
        },
        // Event cancel drag
        cancelDrag() {
            this.mooveHoverOrder = null;
            this.onDragItem = false;
            this.onDragOrder = null;
        },
        // DROP DANS LA COMPOSITION ( volet de droite ) => AFFICHAGE DE LA MODALE CONTENAT LE FORM DE l'ITEM
        addItemToComposition(evt) {
            this.mooveHoverOrder = null;
            this.onDragItem = false;
            this.onDragOrder = null;
            this.submit = false;
            if (this.end == false) {
                this.resetInputs();
                if (evt.title && evt.type) {
                    this.itemForm.title = evt.title;
                    this.itemForm.type = evt.type;
                } else if (evt.dataTransfer.getData('from') == 'itemsList') {
                    this.itemForm.title = evt.dataTransfer.getData('itemtitle');
                    this.itemForm.type = evt.dataTransfer.getData('itemType');
                }
                this.$bvModal.show('modal-lesson-items-form');
            }
        },

        ///////////////////////////////////////////////////////////////////
        ////| DEPLACEMENT / REORGANISATION AU SEIN DE LA COMPOSITION |/////
        /* Deplacement d'un item (Dans la même zone => avant la réogarnisation) */
        startShifting(evt, item) {
            this.onDragOrder = item.order;
            evt.dataTransfer.dropEffect = 'copy';
            evt.dataTransfer.effectAllowed = 'copy';
            evt.dataTransfer.setData('item', JSON.stringify(item));
        },
        /* /onDrop event/ (dans la même zone ) => Réorganisation des items */
        itemsOrderReorganization(evt, item) {
            this.mooveHoverOrder = null;
            this.onDragOrder = null;
            const moovingItem = JSON.parse(evt.dataTransfer.getData('item'));
            const ItemSwaped = item;
            const lastOrderItemSwaped = ItemSwaped.order;
            const lastOrderMoovingItem = moovingItem.order;
            const list = this.lessonItems;

            if (lastOrderItemSwaped < lastOrderMoovingItem) {
                let lastel = null;
                moovingItem.order = lastOrderItemSwaped;
                this.updateItemOrder(moovingItem.id, moovingItem.order);
                list.splice(lastOrderItemSwaped, 1, moovingItem);
                list.forEach(item => {
                    if (item.order > lastOrderItemSwaped && item.order <= lastOrderMoovingItem) {
                        if (lastel != null) {
                            lastel.order++;
                            this.updateItemOrder(lastel.id, lastel.order);
                            list.splice(item.order, 1, lastel);
                        } else {
                            ItemSwaped.order++;
                            this.updateItemOrder(ItemSwaped.id, ItemSwaped.order);
                            list.splice(item.order, 1, ItemSwaped);
                        }
                        lastel = item;
                    }
                });
            } else {
                let previousOrder = null;
                this.lessonItems.forEach(item => {
                    if (item.order >= lastOrderMoovingItem && item.order < lastOrderItemSwaped) {
                        if (previousOrder != null) {
                            const itemOrderInit = item.order;
                            item.order = previousOrder;
                            this.updateItemOrder(item.id, item.order);
                            list.splice(previousOrder, 1, item);
                            previousOrder = itemOrderInit;
                        } else {
                            previousOrder = lastOrderMoovingItem;
                        }
                    } else if (item.order == lastOrderItemSwaped) {
                        moovingItem.order = lastOrderItemSwaped - 1;
                        this.updateItemOrder(moovingItem.id, moovingItem.order);
                        list.splice(moovingItem.order, 1, moovingItem);
                    }
                });
            }
        },
        onDragHover(idOrder) {
            if (idOrder != 0 && this.onDragOrder != idOrder && this.onDragOrder != null && this.onDragOrder != idOrder - 1) {
                this.mooveHoverOrder = idOrder;
            }
        },
        updateItemOrder(item_id, new_order) {
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessonsItems/update', [item_id, new_order]).then(data => {
                return data;
            });
        },
        ///////////////////////////////////////////////////////////////////
        /////////////| SUPPRESSION & MODIFICATION D'UN ITEM |//////////////
        deleteItem(order) {
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessonsItems/delete', [this.$route.params.id, order]).then(() => {
                let lastel = order;
                let list = this.lessonItems;
                list.forEach(function(item) {
                    if (item.order > order) {
                        const old_order_id = item.order;
                        item.order--;
                        list.splice(lastel, 1, item);
                        ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessonsItems/update', [item.id, item.order]);
                        lastel = old_order_id;
                    }
                });
                list.splice(-1, 1);
                this.orderItems--;
            });
            this.deleteItemId = null;
            this.$bvModal.hide('delete-modal');
        },
        editItem: async function(item_order) {
            this.itemForm = [];
            this.resetInputs();
            this.update = true;

            const item = {
                id: this.lessonItems[item_order].id,
                media_id: this.lessonItems[item_order].media_id,
                survey_id: this.lessonItems[item_order].survey_id,
                survey_item_id: this.lessonItems[item_order].survey_item_id,
                order: this.lessonItems[item_order].order,
                type: this.lessonItems[item_order].type,
                title: this.lessonItems[item_order].title,
                description: this.lessonItems[item_order].description,
                text: this.lessonItems[item_order].text,
                link: this.lessonItems[item_order].link,
                test: this.lessonItems[item_order].test,
                file: this.lessonItems[item_order].file,
                message: this.lessonItems[item_order].message,
                goal: this.lessonItems[item_order].goal
            };
            this.itemForm = item;
            this.itemForm.interactive = {};
            this.resetInteractives();
            if (this.itemForm.type == 'question' || this.itemForm.type == 'questionnaire' || this.itemForm.type == 'end') {
                if (this.itemForm.type == 'question' || this.itemForm.type == 'questionnaire' || this.itemForm.type == 'end') {
                    this.showAnswers = false;
                }
                this.focusQuestionForm(this.statesItemForm.onFocus);
                this.statesItemForm.nextFormId = this.itemForm.test.length;
            }

            if (this.itemForm.type === 'question_interactive') {
                this.itemForm.interactive_item = this.lessonItems[item_order].interactive_item;
                this.interactive_id = this.lessonItems[item_order].interactive_item.id;
                this.itemForm.interactive.title = this.lessonItems[item_order].interactive_details.title;
                this.itemForm.interactive.question = this.lessonItems[item_order].interactive_details.question;
                this.itemForm.interactive.consigne = this.lessonItems[item_order].interactive_details.consigne;
                if (this.lessonItems[item_order].interactive_details.explication_bool === 1) {
                    this.itemForm.interactive.explication[0] = 'displayExplication';
                }
                if (this.lessonItems[item_order].interactive_details.explication_txt) {
                    this.itemForm.interactive.explicationText1 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[0];
                    this.itemForm.interactive.explicationText2 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[1];
                    this.itemForm.interactive.explicationText3 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[2];
                    this.itemForm.interactive.explicationText4 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[3];
                    this.itemForm.interactive.explicationText5 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[4];
                    this.itemForm.interactive.explicationText6 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[5];
                    this.itemForm.interactive.explicationText7 = this.lessonItems[item_order].interactive_details.explication_txt.split(
                        '<br>'
                    )[6];
                }
                if (this.lessonItems[item_order].interactive_details.explication_img_name) {
                    this.itemForm.interactive.explicationImage_placeholder = this.lessonItems[
                        item_order
                    ].interactive_details.explication_img_name;
                } else {
                    this.itemForm.interactive.explicationImage_placeholder = 'Choisissez une image ou déposez-la ici';
                }
                // images
                if (this.interactive_id === 1) {
                    this.itemForm.interactive.label1 = this.lessonItems[item_order].interactive_details.label_1;
                    this.itemForm.interactive.x1 = this.lessonItems[item_order].interactive_details.x_1;
                    this.itemForm.interactive.y1 = this.lessonItems[item_order].interactive_details.y_1;
                    this.itemForm.interactive.label2 = this.lessonItems[item_order].interactive_details.label_2;
                    this.itemForm.interactive.x2 = this.lessonItems[item_order].interactive_details.x_2;
                    this.itemForm.interactive.y2 = this.lessonItems[item_order].interactive_details.y_2;
                    this.itemForm.interactive.label3 = this.lessonItems[item_order].interactive_details.label_3;
                    this.itemForm.interactive.x3 = this.lessonItems[item_order].interactive_details.x_3;
                    this.itemForm.interactive.y3 = this.lessonItems[item_order].interactive_details.y_3;
                    this.itemForm.interactive.label4 = this.lessonItems[item_order].interactive_details.label_4;
                    this.itemForm.interactive.x4 = this.lessonItems[item_order].interactive_details.x_4;
                    this.itemForm.interactive.y4 = this.lessonItems[item_order].interactive_details.y_4;
                    this.itemForm.interactive.file = this.lessonItems[item_order].interactive_details.file;
                }
                // Cursors
                if (this.interactive_id === 2) {
                    this.itemForm.interactive.elt_1 = this.lessonItems[item_order].interactive_details.elt_1;
                    this.itemForm.interactive.elt_2 = this.lessonItems[item_order].interactive_details.elt_2;
                    this.itemForm.interactive.elt_3 = this.lessonItems[item_order].interactive_details.elt_3;
                    this.itemForm.interactive.elt_4 = this.lessonItems[item_order].interactive_details.elt_4;
                    this.itemForm.interactive.b_rep = this.lessonItems[item_order].interactive_details.b_rep;
                    this.itemForm.interactive.file = this.lessonItems[item_order].interactive_details.file;
                }
                // QCM Images
                if (this.interactive_id === 3) {
                    this.itemForm.interactive.qcm_checkbox1 = this.lessonItems[item_order].interactive_details.elt_1_status;
                    this.itemForm.interactive.qcm_checkbox2 = this.lessonItems[item_order].interactive_details.elt_2_status;
                    this.itemForm.interactive.qcm_checkbox3 = this.lessonItems[item_order].interactive_details.elt_3_status;
                    this.itemForm.interactive.qcm_checkbox4 = this.lessonItems[item_order].interactive_details.elt_4_status;
                    if (this.lessonItems[item_order].interactive_details.elt_1_name) {
                        this.itemForm.interactive.qcm_image1_placeholder = this.lessonItems[item_order].interactive_details.elt_1_name;
                    } else {
                        this.itemForm.interactive.qcm_image1_placeholder = 'Choisissez une image ou déposez-la ici';
                    }
                    if (this.lessonItems[item_order].interactive_details.elt_2_name) {
                        this.itemForm.interactive.qcm_image2_placeholder = this.lessonItems[item_order].interactive_details.elt_2_name;
                    } else {
                        this.itemForm.interactive.qcm_image2_placeholder = 'Choisissez une image ou déposez-la ici';
                    }
                    if (this.lessonItems[item_order].interactive_details.elt_3_name) {
                        this.itemForm.interactive.qcm_image3_placeholder = this.lessonItems[item_order].interactive_details.elt_3_name;
                    } else {
                        this.itemForm.interactive.qcm_image3_placeholder = 'Choisissez une image ou déposez-la ici';
                    }
                    if (this.lessonItems[item_order].interactive_details.elt_4_name) {
                        this.itemForm.interactive.qcm_image4_placeholder = this.lessonItems[item_order].interactive_details.elt_4_name;
                    } else {
                        this.itemForm.interactive.qcm_image4_placeholder = 'Choisissez une image ou déposez-la ici';
                    }
                }
                // Relier les points
                if (this.interactive_id === 4) {
                    if (this.lessonItems[item_order].interactive_details.elt_1_img) {
                        this.itemForm.interactive.checked_left = true;
                        this.itemForm.interactive.elt_1_placeholder = this.lessonItems[item_order].interactive_details.elt_1_name;
                        this.itemForm.interactive.elt_2_placeholder = this.lessonItems[item_order].interactive_details.elt_2_name;
                        if (this.lessonItems[item_order].interactive_details.elt_3_img) {
                            this.itemForm.interactive.elt_3_placeholder = this.lessonItems[item_order].interactive_details.elt_3_name;
                        } else {
                            this.itemForm.interactive.elt_3_placeholder = 'Choisissez une image ou déposez-la ici';
                        }
                        if (this.lessonItems[item_order].interactive_details.elt_4_img) {
                            this.itemForm.interactive.elt_4_placeholder = this.lessonItems[item_order].interactive_details.elt_4_name;
                        } else {
                            this.itemForm.interactive.elt_4_placeholder = 'Choisissez une image ou déposez-la ici';
                        }
                    } else {
                        this.itemForm.interactive.checked_left = false;
                        this.itemForm.interactive.elt_1 = this.lessonItems[item_order].interactive_details.elt_1_text;
                        this.itemForm.interactive.elt_2 = this.lessonItems[item_order].interactive_details.elt_2_text;
                        this.itemForm.interactive.elt_3 = this.lessonItems[item_order].interactive_details.elt_3_text;
                        this.itemForm.interactive.elt_4 = this.lessonItems[item_order].interactive_details.elt_4_text;
                    }

                    if (this.lessonItems[item_order].interactive_details.rep_1_img) {
                        this.itemForm.interactive.checked_right = true;
                        this.itemForm.interactive.rep_1_placeholder = this.lessonItems[item_order].interactive_details.rep_1_name;
                        this.itemForm.interactive.rep_2_placeholder = this.lessonItems[item_order].interactive_details.rep_2_name;
                        if (this.lessonItems[item_order].interactive_details.rep_3_img) {
                            this.itemForm.interactive.rep_3_placeholder = this.lessonItems[item_order].interactive_details.rep_3_name;
                        } else {
                            this.itemForm.interactive.rep_3_placeholder = 'Choisissez une image ou déposez-la ici';
                        }
                        if (this.lessonItems[item_order].interactive_details.rep_4_img) {
                            this.itemForm.interactive.rep_4_placeholder = this.lessonItems[item_order].interactive_details.rep_4_name;
                        } else {
                            this.itemForm.interactive.rep_4_placeholder = 'Choisissez une image ou déposez-la ici';
                        }
                    } else {
                        this.itemForm.interactive.checked_right = false;
                        this.itemForm.interactive.rep_1 = this.lessonItems[item_order].interactive_details.rep_1_text;
                        this.itemForm.interactive.rep_2 = this.lessonItems[item_order].interactive_details.rep_2_text;
                        this.itemForm.interactive.rep_3 = this.lessonItems[item_order].interactive_details.rep_3_text;
                        this.itemForm.interactive.rep_4 = this.lessonItems[item_order].interactive_details.rep_4_text;
                    }
                }
                // Texte a trous
                if (this.interactive_id === 5) {
                    this.itemForm.interactive.max_input = 23;
                    this.itemForm.interactive.elt_1 = this.lessonItems[item_order].interactive_details.elt_1;
                    this.itemForm.interactive.elt_2 = this.lessonItems[item_order].interactive_details.elt_2;
                    this.itemForm.interactive.rep_1 = this.lessonItems[item_order].interactive_details.rep_1;
                    this.itemForm.interactive.rep_2 = this.lessonItems[item_order].interactive_details.rep_2;
                    this.itemForm.interactive.rep_3 = this.lessonItems[item_order].interactive_details.rep_3;
                }
                // QCM
                if (this.interactive_id === 6) {
                    this.itemForm.interactive.elt_1 = this.lessonItems[item_order].interactive_details.elt_1;
                    this.itemForm.interactive.elt_2 = this.lessonItems[item_order].interactive_details.elt_2;
                    this.itemForm.interactive.elt_3 = this.lessonItems[item_order].interactive_details.elt_3;
                    this.itemForm.interactive.elt_4 = this.lessonItems[item_order].interactive_details.elt_4;
                    this.itemForm.interactive.qcm_checkbox1 = this.lessonItems[item_order].interactive_details.elt_1_status;
                    this.itemForm.interactive.qcm_checkbox2 = this.lessonItems[item_order].interactive_details.elt_2_status;
                    this.itemForm.interactive.qcm_checkbox3 = this.lessonItems[item_order].interactive_details.elt_3_status;
                    this.itemForm.interactive.qcm_checkbox4 = this.lessonItems[item_order].interactive_details.elt_4_status;
                }
                //Ranger les images
                if (this.interactive_id === 7) {
                    if (this.lessonItems[item_order].interactive_details.elt_1_img) {
                        this.itemForm.interactive.checked_left = true;
                        this.itemForm.interactive.elt_1_placeholder = this.lessonItems[item_order].interactive_details.elt_1_name;
                        this.itemForm.interactive.elt_2_placeholder = this.lessonItems[item_order].interactive_details.elt_2_name;
                        if (this.lessonItems[item_order].interactive_details.elt_3_img) {
                            this.itemForm.interactive.elt_3_placeholder = this.lessonItems[item_order].interactive_details.elt_3_name;
                        } else {
                            this.itemForm.interactive.elt_3_placeholder = 'Choisissez une image ou déposez-la ici';
                        }
                        if (this.lessonItems[item_order].interactive_details.elt_4_img) {
                            this.itemForm.interactive.elt_4_placeholder = this.lessonItems[item_order].interactive_details.elt_4_name;
                        } else {
                            this.itemForm.interactive.elt_4_placeholder = 'Choisissez une image ou déposez-la ici';
                        }
                    } else {
                        this.itemForm.interactive.checked_left = false;
                        this.itemForm.interactive.elt_1 = this.lessonItems[item_order].interactive_details.elt_1_text;
                        this.itemForm.interactive.elt_2 = this.lessonItems[item_order].interactive_details.elt_2_text;
                        this.itemForm.interactive.elt_3 = this.lessonItems[item_order].interactive_details.elt_3_text;
                        this.itemForm.interactive.elt_4 = this.lessonItems[item_order].interactive_details.elt_4_text;
                    }
                    this.itemForm.interactive.rep_1_placeholder = this.lessonItems[item_order].interactive_details.rep_1_name;
                    this.itemForm.interactive.rep_2_placeholder = this.lessonItems[item_order].interactive_details.rep_2_name;
                    if (this.lessonItems[item_order].interactive_details.rep_3) {
                        this.itemForm.interactive.rep_3_placeholder = this.lessonItems[item_order].interactive_details.rep_3_name;
                    } else {
                        this.itemForm.interactive.rep_3_placeholder = 'Choisissez une image ou déposez-la ici';
                    }
                    if (this.lessonItems[item_order].interactive_details.rep_4) {
                        this.itemForm.interactive.rep_4_placeholder = this.lessonItems[item_order].interactive_details.rep_4_name;
                    } else {
                        this.itemForm.interactive.rep_4_placeholder = 'Choisissez une image ou déposez-la ici';
                    }
                }
            }
            this.$bvModal.show('modal-lesson-items-form');
        },
        //Sur le formulaire de l'interaction Interactives links (relier les points) les champs file ou text ne se mettent pas à jour lors de l'edit.
        //leur attribuer une clé et modifier cette clé à chaque click sur le switch permet de rafraichir l'affichage et donc d'afficher les formulaires correspondants au switch.
        forceRerender() {
            this.ForceReRender += 1;
        },
        ///////////////////////////////////////////////////////////////////
        ////////////////| VERIFICATION DES INFOS DU FORM |/////////////////
        // Verifier les valeurs des inputs du form
        checkFormInputsValidity() {
            this.statesItemForm.title = !!this.itemForm.title;

            if (
                this.itemForm.type !== 'question' &&
                this.itemForm.type !== 'questionnaire' &&
                this.itemForm.type !== 'end' &&
                this.itemForm.type !== 'question_interactive'
            ) {
                this.statesItemForm.description = !!this.itemForm.description;
            }
            this.statesItemForm.text = true;
            this.statesItemForm.link = true;
            this.statesItemForm.test = true;
            if (this.itemForm.type === 'video' || this.itemForm.type === 'image' || this.itemForm.type === 'pdf') {
                if (this.itemForm.id && !this.itemForm.file) {
                    this.statesItemForm.file = true;
                } else if (this.itemForm.file) {
                    if (this.itemForm.file.type.split('/')[0] == this.itemForm.type && this.itemForm.type !== 'pdf') {
                        this.statesItemForm.file = true;
                    } else if (this.itemForm.file.type.split('/')[1] == this.itemForm.type && this.itemForm.type == 'pdf') {
                        this.statesItemForm.file = true;
                    } else {
                        this.statesItemForm.file = false;
                    }
                } else {
                    this.statesItemForm.file = false;
                }
            }
            if (this.itemForm.type === 'video_interactive') {
                if (this.itemForm.file && this.itemForm.file.type.split('/')[1].includes('zip')) {
                    this.statesItemForm.file = true;
                } else {
                    this.statesItemForm.file = false;
                }
            }
            if (this.itemForm.type != null) {
                this.statesItemForm.type = true;
            } else {
                this.statesItemForm.type = false;
            }
            if (this.itemForm.type === 'question_interactive') {
                if (!this.verifInteractive()) {
                    return false;
                }
            }
            this.statesItemForm.next = true;
            this.statesItemForm.previous = true;

            for (const [key, value] of Object.entries(this.statesItemForm)) {
                //La vérification des items intéractifs est déjà faite dans la fonction checkFormInputsValidity, on ne vérifie pas ici
                if (key === 'interactive') {
                    continue;
                } else if (value === false) {
                    return false;
                }
            }
            return true;
        },
        verifInteractive() {
            //state global
            if (this.itemForm.interactive.title) {
                this.statesItemForm.interactive.title = true;
            } else {
                this.statesItemForm.interactive.title = false;
                return false;
            }

            if (
                (this.selected_question_interactive && this.selected_question_interactive.id !== 5) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id !== 5)
            ) {
                if (this.itemForm.interactive.question) {
                    this.statesItemForm.interactive.question = true;
                } else {
                    this.statesItemForm.interactive.question = false;
                    return false;
                }
            }

            //state interactives Images
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 1) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 1)
            ) {
                if (this.itemForm.interactive.file) {
                    if (this.itemForm.interactive.file.type === 'image/png' || this.itemForm.interactive.file.type === 'image/jpeg') {
                        this.statesItemForm.interactive.file = true;
                    }
                } else if (this.update === true) {
                    this.statesItemForm.interactive.file = true;
                } else {
                    this.statesItemForm.interactive.file = false;
                    return false;
                }

                if (
                    this.itemForm.interactive.label1 != null &&
                    this.itemForm.interactive.x1 != null &&
                    this.itemForm.interactive.y1 != null
                ) {
                    this.statesItemForm.interactive.point1 = true;
                } else {
                    this.statesItemForm.interactive.point1 = false;
                    return false;
                }

                if (
                    this.itemForm.interactive.label2 != null &&
                    this.itemForm.interactive.x2 != null &&
                    this.itemForm.interactive.y2 != null
                ) {
                    this.statesItemForm.interactive.point2 = true;
                } else {
                    this.statesItemForm.interactive.point2 = false;
                    return false;
                }
            }

            //state interactives cursors
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 2) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 2)
            ) {
                if (this.itemForm.interactive.elt_1) {
                    this.statesItemForm.interactive.elt_1 = true;
                } else {
                    this.statesItemForm.interactive.elt_1 = false;
                    return false;
                }
                if (this.itemForm.interactive.elt_2) {
                    this.statesItemForm.interactive.elt_2 = true;
                } else {
                    this.statesItemForm.interactive.elt_2 = false;
                    return false;
                }
                if (this.itemForm.interactive.elt_3) {
                    this.statesItemForm.interactive.elt_3 = true;
                } else {
                    this.statesItemForm.interactive.elt_3 = false;
                    return false;
                }
                if (this.itemForm.interactive.elt_4) {
                    this.statesItemForm.interactive.elt_4 = true;
                } else {
                    this.statesItemForm.interactive.elt_4 = false;
                    return false;
                }
                if (this.itemForm.interactive.b_rep) {
                    this.statesItemForm.interactive.b_rep = true;
                } else {
                    this.statesItemForm.interactive.b_rep = false;
                    return false;
                }
            }

            //state interactives QCM Image
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 3) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 3)
            ) {
                if (
                    this.itemForm.interactive.qcm_image1 ||
                    this.itemForm.interactive.qcm_image1_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    this.statesItemForm.interactive.qcm_image1 = true;
                } else {
                    this.statesItemForm.interactive.qcm_image1 = false;
                    return false;
                }
                if (
                    this.itemForm.interactive.qcm_image2 ||
                    this.itemForm.interactive.qcm_image2_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    this.statesItemForm.interactive.qcm_image2 = true;
                } else {
                    this.statesItemForm.interactive.qcm_image2 = false;
                    return false;
                }

                if (this.itemForm.interactive.qcm_checkbox1 === 1) {
                    if (
                        this.itemForm.interactive.qcm_image1 ||
                        this.itemForm.interactive.qcm_image1_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.qcm_checkbox = true;
                    } else {
                        this.statesItemForm.interactive.qcm_checkbox = false;
                        return false;
                    }
                }

                if (this.itemForm.interactive.qcm_checkbox2 === 1) {
                    if (
                        this.itemForm.interactive.qcm_image2 ||
                        this.itemForm.interactive.qcm_image2_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.qcm_checkbox = true;
                    } else {
                        this.statesItemForm.interactive.qcm_checkbox = false;
                        return false;
                    }
                }

                if (this.itemForm.interactive.qcm_checkbox3 == 1) {
                    if (
                        this.itemForm.interactive.qcm_image3 ||
                        this.itemForm.interactive.qcm_image3_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.qcm_checkbox = true;
                    } else {
                        this.statesItemForm.interactive.qcm_checkbox = false;
                        return false;
                    }
                }

                if (this.itemForm.interactive.qcm_checkbox4 === 1) {
                    if (
                        this.itemForm.interactive.qcm_image4 ||
                        this.itemForm.interactive.qcm_image4_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.qcm_checkbox = true;
                    } else {
                        this.statesItemForm.interactive.qcm_checkbox = false;
                        return false;
                    }
                }

                if (
                    this.itemForm.interactive.qcm_checkbox1 === 1 ||
                    this.itemForm.interactive.qcm_checkbox2 === 1 ||
                    this.itemForm.interactive.qcm_checkbox3 === 1 ||
                    this.itemForm.interactive.qcm_checkbox4 == 1
                ) {
                    this.statesItemForm.interactive.qcm_checkbox = true;
                } else {
                    this.statesItemForm.interactive.qcm_checkbox = false;
                    return false;
                }
            }
            //state Interactives_links
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 4) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 4)
            ) {
                if (
                    this.itemForm.interactive.elt_1 ||
                    this.itemForm.interactive.elt_1_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    if (
                        this.itemForm.interactive.rep_1 ||
                        this.itemForm.interactive.rep_1_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.elt_1 = true;
                        this.statesItemForm.interactive.rep_1 = true;
                    } else {
                        this.statesItemForm.interactive.elt_1 = true;
                        this.statesItemForm.interactive.rep_1 = false;
                        return false;
                    }
                } else {
                    this.statesItemForm.interactive.elt_1 = false;
                    this.statesItemForm.interactive.rep_1 = false;
                    return false;
                }
                if (
                    this.itemForm.interactive.elt_2 ||
                    this.itemForm.interactive.elt_2_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    if (
                        this.itemForm.interactive.rep_2 ||
                        this.itemForm.interactive.rep_2_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.elt_2 = true;
                        this.statesItemForm.interactive.rep_2 = true;
                    } else {
                        this.statesItemForm.interactive.elt_2 = true;
                        this.statesItemForm.interactive.rep_2 = false;
                        return false;
                    }
                } else {
                    this.statesItemForm.interactive.elt_2 = false;
                    this.statesItemForm.interactive.rep_2 = false;
                    return false;
                }
                for (let id = 1; id < 5; id++) {
                    let elt = 'elt_' + id;
                    let rep = 'rep_' + id;
                    let elt_placeholder = 'elt_' + id + '_placeholder';
                    let rep_placeholder = 'rep_' + id + '_placeholder';

                    //Vérifie qu'une réponse à été saisie pour chaques propositions
                    if (
                        this.itemForm.interactive[elt] ||
                        (this.itemForm.interactive[elt_placeholder] &&
                            this.itemForm.interactive[elt_placeholder] !== 'Choisissez une image ou déposez-la ici')
                    ) {
                        if (
                            this.itemForm.interactive[rep] ||
                            (this.itemForm.interactive[rep_placeholder] &&
                                this.itemForm.interactive[rep_placeholder] !== 'Choisissez une image ou déposez-la ici')
                        ) {
                            this.statesItemForm.interactive[elt] = true;
                            this.statesItemForm.interactive[rep] = true;
                        } else {
                            this.statesItemForm.interactive[rep] = false;
                            return false;
                        }
                    }

                    //Vérifie qu'une proposition à été saisie pour chaques réponses
                    if (
                        this.itemForm.interactive[rep] ||
                        (this.itemForm.interactive[rep_placeholder] &&
                            this.itemForm.interactive[rep_placeholder] !== 'Choisissez une image ou déposez-la ici')
                    ) {
                        if (
                            this.itemForm.interactive[elt] ||
                            this.itemForm.interactive[elt_placeholder] !== 'Choisissez une image ou déposez-la ici'
                        ) {
                            this.statesItemForm.interactive[elt] = true;
                            this.statesItemForm.interactive[rep] = true;
                        } else {
                            this.statesItemForm.interactive[elt] = false;
                            return false;
                        }
                    }
                }

                if (
                    this.itemForm.interactive.elt_4 ||
                    this.itemForm.interactive.elt_4_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    if (
                        this.itemForm.interactive.elt_3 ||
                        this.itemForm.interactive.elt_3_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.elt_3 = true;
                        this.statesItemForm.interactive.rep_3 = true;
                    } else {
                        this.statesItemForm.interactive.elt_3 = false;
                        this.statesItemForm.interactive.rep_3 = false;
                        return false;
                    }
                }
            }
            //state Interactives_texte_a_trou
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 5) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 5)
            ) {
                if (this.itemForm.interactive.elt_1) {
                    this.statesItemForm.interactive.elt_1 = true;
                } else {
                    this.statesItemForm.interactive.elt_1 = false;
                    return false;
                }

                if (this.itemForm.interactive.rep_1) {
                    this.statesItemForm.interactive.rep_1 = true;
                } else {
                    this.statesItemForm.interactive.rep_1 = false;
                    return false;
                }

                if (this.itemForm.interactive.elt_2) {
                    this.statesItemForm.interactive.elt_2 = true;
                } else {
                    this.statesItemForm.interactive.elt_2 = false;
                    return false;
                }

                if (this.itemForm.interactive.rep_2) {
                    this.statesItemForm.interactive.rep_2 = true;
                } else {
                    this.statesItemForm.interactive.rep_2 = false;
                    return false;
                }
            }
            //state Interactives_qcm
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 6) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 6)
            ) {
                for (let id = 1; id < 5; id++) {
                    let qcm_checkbox = 'qcm_checkbox' + id;
                    let elt = 'elt_' + id;
                    if (this.itemForm.interactive[qcm_checkbox] === 1) {
                        if (this.itemForm.interactive[elt]) {
                            this.statesItemForm.interactive.qcm_checkbox = true;
                        } else {
                            this.statesItemForm.interactive.qcm_checkbox = false;
                            return false;
                        }
                    }
                }

                if (
                    this.itemForm.interactive.qcm_checkbox1 == 1 ||
                    this.itemForm.interactive.qcm_checkbox2 == 1 ||
                    this.itemForm.interactive.qcm_checkbox3 == 1 ||
                    this.itemForm.interactive.qcm_checkbox4 == 1
                ) {
                    this.statesItemForm.interactive.qcm_checkbox = true;
                } else {
                    this.statesItemForm.interactive.qcm_checkbox = false;
                    return false;
                }

                if (this.itemForm.interactive.elt_1) {
                    this.statesItemForm.interactive.elt_1 = true;
                } else {
                    this.statesItemForm.interactive.elt_1 = false;
                    return false;
                }

                if (this.itemForm.interactive.elt_2) {
                    this.statesItemForm.interactive.elt_2 = true;
                } else {
                    this.statesItemForm.interactive.elt_2 = false;
                    return false;
                }
            }
            //state Interactives_ranger_images
            if (
                (this.selected_question_interactive && this.selected_question_interactive.id === 7) ||
                (this.itemForm.interactive_item && this.itemForm.interactive_item.id === 7)
            ) {
                if (
                    this.itemForm.interactive.elt_1 ||
                    this.itemForm.interactive.elt_1_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    if (
                        this.itemForm.interactive.rep_1 ||
                        this.itemForm.interactive.rep_1_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.elt_1 = true;
                        this.statesItemForm.interactive.rep_1 = true;
                    } else {
                        this.statesItemForm.interactive.elt_1 = true;
                        this.statesItemForm.interactive.rep_1 = false;
                        return false;
                    }
                } else {
                    this.statesItemForm.interactive.elt_1 = false;
                    this.statesItemForm.interactive.rep_1 = false;
                    return false;
                }
                if (
                    this.itemForm.interactive.elt_2 ||
                    this.itemForm.interactive.elt_2_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    if (
                        this.itemForm.interactive.rep_2 ||
                        this.itemForm.interactive.rep_2_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.elt_2 = true;
                        this.statesItemForm.interactive.rep_2 = true;
                    } else {
                        this.statesItemForm.interactive.elt_2 = true;
                        this.statesItemForm.interactive.rep_2 = false;
                        return false;
                    }
                } else {
                    this.statesItemForm.interactive.elt_2 = false;
                    this.statesItemForm.interactive.rep_2 = false;
                    return false;
                }
                for (let id = 1; id < 5; id++) {
                    let elt = 'elt_' + id;
                    let rep = 'rep_' + id;
                    let elt_placeholder = 'elt_' + id + '_placeholder';
                    let rep_placeholder = 'rep_' + id + '_placeholder';

                    //Vérifie qu'une réponse à été saisie pour chaques propositions
                    if (
                        this.itemForm.interactive[elt] ||
                        (this.itemForm.interactive[elt_placeholder] &&
                            this.itemForm.interactive[elt_placeholder] !== 'Choisissez une image ou déposez-la ici')
                    ) {
                        if (
                            this.itemForm.interactive[rep] ||
                            (this.itemForm.interactive[rep_placeholder] &&
                                this.itemForm.interactive[rep_placeholder] !== 'Choisissez une image ou déposez-la ici')
                        ) {
                            this.statesItemForm.interactive[elt] = true;
                            this.statesItemForm.interactive[rep] = true;
                        } else {
                            this.statesItemForm.interactive[rep] = false;
                            return false;
                        }
                    }

                    //Vérifie qu'une proposition à été saisie pour chaques réponses
                    if (
                        this.itemForm.interactive[rep] ||
                        (this.itemForm.interactive[rep_placeholder] &&
                            this.itemForm.interactive[rep_placeholder] !== 'Choisissez une image ou déposez-la ici')
                    ) {
                        if (
                            this.itemForm.interactive[elt] ||
                            this.itemForm.interactive[elt_placeholder] !== 'Choisissez une image ou déposez-la ici'
                        ) {
                            this.statesItemForm.interactive[elt] = true;
                            this.statesItemForm.interactive[rep] = true;
                        } else {
                            this.statesItemForm.interactive[elt] = false;
                            return false;
                        }
                    }
                }

                if (
                    this.itemForm.interactive.elt_4 ||
                    this.itemForm.interactive.elt_4_placeholder !== 'Choisissez une image ou déposez-la ici'
                ) {
                    if (
                        this.itemForm.interactive.elt_3 ||
                        this.itemForm.interactive.elt_3_placeholder !== 'Choisissez une image ou déposez-la ici'
                    ) {
                        this.statesItemForm.interactive.elt_3 = true;
                        this.statesItemForm.interactive.rep_3 = true;
                    } else {
                        this.statesItemForm.interactive.elt_3 = false;
                        this.statesItemForm.interactive.rep_3 = false;
                        return false;
                    }
                }
            }
            return true;
        },
        // Ferme la modal et ajout l'item a la liste de la composition
        validForm(bvModalEvt) {
            if (this.itemForm.type == 'question' || this.itemForm.type == 'questionnaire' || this.itemForm.type == 'end') {
                this.validQuestionsForm(this.statesItemForm.onFocus);
                if (this.error.state) {
                    bvModalEvt.preventDefault();
                    return;
                }
            }
            bvModalEvt.preventDefault();
            this.$bvModal.hide('modal-lesson-items-form');
            if (this.itemForm.id) {
                this.updateItem();
            } else {
                this.addItem();
            }
        },
        continueForm() {
            // verification si title et message en cas d'ehec sont renseigné
            if (this.itemForm.title === '') {
                this.error.state = true;
                this.error.title = false;
            } else {
                this.error.title = true;
            }

            if (this.itemForm.message === null || this.itemForm.message === '') {
                this.error.errorMessage = false;
                this.error.state = true;
            } else {
                this.error.errorMessage = true;
            }

            if (this.error.errorMessage && this.error.title) {
                this.error.state = false;
            }

            if (this.error.state) {
                this.error.state = false;
                return;
            }

            this.showAnswers = !this.showAnswers;
        },
        //Formater l'explication
        explicationTextFormat() {
            //Si le tableau est vide, on return null
            if (this.itemForm.interactive.explication.length < 1) {
                return null;
            }
            if (this.itemForm.interactive.explicationText1 === null) {
                this.itemForm.interactive.explicationText1 = '';
            }
            if (this.itemForm.interactive.explicationText2 === null) {
                this.itemForm.interactive.explicationText2 = '';
            }
            if (this.itemForm.interactive.explicationText3 === null) {
                this.itemForm.interactive.explicationText3 = '';
            }
            if (this.itemForm.interactive.explicationText4 === null) {
                this.itemForm.interactive.explicationText4 = '';
            }
            if (this.itemForm.interactive.explicationText5 === null) {
                this.itemForm.interactive.explicationText5 = '';
            }
            if (this.itemForm.interactive.explicationText6 === null) {
                this.itemForm.interactive.explicationText6 = '';
            }
            if (this.itemForm.interactive.explicationText7 === null) {
                this.itemForm.interactive.explicationText7 = '';
            }
            return (
                this.itemForm.interactive.explicationText1 +
                '<br>' +
                this.itemForm.interactive.explicationText2 +
                '<br>' +
                this.itemForm.interactive.explicationText3 +
                '<br>' +
                this.itemForm.interactive.explicationText4 +
                '<br>' +
                this.itemForm.interactive.explicationText5 +
                '<br>' +
                this.itemForm.interactive.explicationText6 +
                '<br>' +
                this.itemForm.interactive.explicationText7
            );
        },
        ///////////////////////////////////////////////////////////////////
        /////////////////| AJOUT DES ITEMS & FIN COMPO |///////////////////
        addItem() {
            //conditions pour savoir si le formulaire est de type 'questionaire', 'question' ou 'end'
            if (this.itemForm.type == 'question' || this.itemForm.type == 'questionnaire' || this.itemForm.type == 'end') {
                if (this.error.state) {
                    return;
                }
            }
            if (!this.checkFormInputsValidity()) {
                // Si les entrées du user sont invalides
                return;
            } else {
                this.submit = true;
                let URLFILE = null;
                if (
                    this.itemForm.type == 'video' ||
                    this.itemForm.type == 'image' ||
                    this.itemForm.type == 'pdf' ||
                    this.itemForm.type == 'video_interactive'
                ) {
                    URLFILE = URL.createObjectURL(this.itemForm.file);
                }

                const interactivesImages = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    label_1: this.itemForm.interactive.label1,
                    x_1: this.itemForm.interactive.x1,
                    y_1: this.itemForm.interactive.y1,
                    label_2: this.itemForm.interactive.label2,
                    x_2: this.itemForm.interactive.x2,
                    y_2: this.itemForm.interactive.y2,
                    label_3: this.itemForm.interactive.label3,
                    x_3: this.itemForm.interactive.x3,
                    y_3: this.itemForm.interactive.y3,
                    label_4: this.itemForm.interactive.label4,
                    x_4: this.itemForm.interactive.x4,
                    y_4: this.itemForm.interactive.y4,
                    file: this.itemForm.interactive.file
                };

                const interactivesCursors = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    file: this.itemForm.interactive.file,
                    elt_1: this.itemForm.interactive.elt_1,
                    elt_2: this.itemForm.interactive.elt_2,
                    elt_3: this.itemForm.interactive.elt_3,
                    elt_4: this.itemForm.interactive.elt_4,
                    b_rep: this.itemForm.interactive.b_rep
                };

                const interactivesQCMImages = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    qcm_image1: this.itemForm.interactive.qcm_image1,
                    qcm_image2: this.itemForm.interactive.qcm_image2,
                    qcm_image3: this.itemForm.interactive.qcm_image3,
                    qcm_image4: this.itemForm.interactive.qcm_image4,
                    elt_1_status: this.itemForm.interactive.qcm_checkbox1,
                    elt_2_status: this.itemForm.interactive.qcm_checkbox2,
                    elt_3_status: this.itemForm.interactive.qcm_checkbox3,
                    elt_4_status: this.itemForm.interactive.qcm_checkbox4
                };

                const interactivesLinks = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    elt_1: this.itemForm.interactive.elt_1,
                    elt_2: this.itemForm.interactive.elt_2,
                    elt_3: this.itemForm.interactive.elt_3,
                    elt_4: this.itemForm.interactive.elt_4,
                    rep_1: this.itemForm.interactive.rep_1,
                    rep_2: this.itemForm.interactive.rep_2,
                    rep_3: this.itemForm.interactive.rep_3,
                    rep_4: this.itemForm.interactive.rep_4
                };

                const interactivesTexteATrou = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    elt_1: this.itemForm.interactive.elt_1,
                    elt_2: this.itemForm.interactive.elt_2,
                    rep_1: this.itemForm.interactive.rep_1,
                    rep_2: this.itemForm.interactive.rep_2,
                    rep_3: this.itemForm.interactive.rep_3
                };

                const interactivesQCM = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    elt_1: this.itemForm.interactive.elt_1,
                    elt_2: this.itemForm.interactive.elt_2,
                    elt_3: this.itemForm.interactive.elt_3,
                    elt_4: this.itemForm.interactive.elt_4,
                    elt_1_status: this.itemForm.interactive.qcm_checkbox1,
                    elt_2_status: this.itemForm.interactive.qcm_checkbox2,
                    elt_3_status: this.itemForm.interactive.qcm_checkbox3,
                    elt_4_status: this.itemForm.interactive.qcm_checkbox4
                };

                const interactivesRangerImages = {
                    title: this.itemForm.interactive.title,
                    question: this.itemForm.interactive.question,
                    consigne: this.itemForm.interactive.consigne,
                    explicationImage: this.itemForm.interactive.explicationImage,
                    explicationText: this.explicationTextFormat(),
                    elt_1: this.itemForm.interactive.elt_1,
                    elt_2: this.itemForm.interactive.elt_2,
                    elt_3: this.itemForm.interactive.elt_3,
                    elt_4: this.itemForm.interactive.elt_4,
                    rep_1: this.itemForm.interactive.rep_1,
                    rep_2: this.itemForm.interactive.rep_2,
                    rep_3: this.itemForm.interactive.rep_3,
                    rep_4: this.itemForm.interactive.rep_4
                };

                const item = {
                    id: null,
                    media_id: null,
                    survey_id: null,
                    survey_item_id: null,
                    type: this.itemForm.type,
                    order: this.orderItems + 1,
                    title: this.itemForm.title,
                    description: this.itemForm.description,
                    text: this.itemForm.text,
                    message: this.itemForm.message,
                    goal: this.itemForm.goal,
                    link: this.itemForm.link,
                    test: this.itemForm.test,
                    file: this.itemForm.file,
                    fileUrl: URLFILE
                };

                if (this.itemForm.type == 'question_interactive') {
                    item.interactives_items_id = this.selected_question_interactive.id;
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 1) {
                        if (this.itemForm.interactive.file) {
                            let INTERACTIVEURLFILE = URL.createObjectURL(this.itemForm.interactive.file);
                            interactivesImages.fileUrl = INTERACTIVEURLFILE;
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesImages.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    } else if (this.selected_question_interactive && this.selected_question_interactive.id === 2) {
                        if (this.itemForm.interactive.file) {
                            let INTERACTIVEURLFILE = URL.createObjectURL(this.itemForm.interactive.file);
                            interactivesCursors.fileUrl = INTERACTIVEURLFILE;
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesCursors.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    } else if (this.selected_question_interactive && this.selected_question_interactive.id === 3) {
                        if (this.itemForm.interactive.qcm_image1) {
                            let INTERACTIVEURLFILEELT1 = URL.createObjectURL(this.itemForm.interactive.qcm_image1);
                            interactivesQCMImages.fileUrlElt1 = INTERACTIVEURLFILEELT1;
                        }
                        if (this.itemForm.interactive.qcm_image2) {
                            let INTERACTIVEURLFILEELT2 = URL.createObjectURL(this.itemForm.interactive.qcm_image2);
                            interactivesQCMImages.fileUrlElt2 = INTERACTIVEURLFILEELT2;
                        }
                        if (this.itemForm.interactive.qcm_image3) {
                            let INTERACTIVEURLFILEELT3 = URL.createObjectURL(this.itemForm.interactive.qcm_image3);
                            interactivesQCMImages.fileUrlElt3 = INTERACTIVEURLFILEELT3;
                        }
                        if (this.itemForm.interactive.qcm_image4) {
                            let INTERACTIVEURLFILEELT4 = URL.createObjectURL(this.itemForm.interactive.qcm_image4);
                            interactivesQCMImages.fileUrlElt4 = INTERACTIVEURLFILEELT4;
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesQCMImages.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    } else if (this.selected_question_interactive && this.selected_question_interactive.id === 4) {
                        if (this.itemForm.interactive.elt_1 && this.itemForm.interactive.elt_1.type) {
                            let INTERACTIVEURLFILEELT1 = URL.createObjectURL(this.itemForm.interactive.elt_1);
                            interactivesLinks.fileUrlElt1 = INTERACTIVEURLFILEELT1;
                        }
                        if (this.itemForm.interactive.elt_2 && this.itemForm.interactive.elt_2.type) {
                            let INTERACTIVEURLFILEELT2 = URL.createObjectURL(this.itemForm.interactive.elt_2);
                            interactivesLinks.fileUrlElt2 = INTERACTIVEURLFILEELT2;
                        }
                        if (this.itemForm.interactive.elt_3 && this.itemForm.interactive.elt_3.type) {
                            let INTERACTIVEURLFILEELT3 = URL.createObjectURL(this.itemForm.interactive.elt_3);
                            interactivesLinks.fileUrlElt3 = INTERACTIVEURLFILEELT3;
                        }
                        if (this.itemForm.interactive.elt_4 && this.itemForm.interactive.elt_4.type) {
                            let INTERACTIVEURLFILEELT4 = URL.createObjectURL(this.itemForm.interactive.elt_4);
                            interactivesLinks.fileUrlElt4 = INTERACTIVEURLFILEELT4;
                        }
                        if (this.itemForm.interactive.rep_1 && this.itemForm.interactive.rep_1.type) {
                            let INTERACTIVEURLFILEREP1 = URL.createObjectURL(this.itemForm.interactive.rep_1);
                            interactivesLinks.fileUrlRep1 = INTERACTIVEURLFILEREP1;
                        }
                        if (this.itemForm.interactive.rep_2 && this.itemForm.interactive.rep_2.type) {
                            let INTERACTIVEURLFILEREP2 = URL.createObjectURL(this.itemForm.interactive.rep_2);
                            interactivesLinks.fileUrlRep2 = INTERACTIVEURLFILEREP2;
                        }
                        if (this.itemForm.interactive.rep_3 && this.itemForm.interactive.rep_3.type) {
                            let INTERACTIVEURLFILEREP3 = URL.createObjectURL(this.itemForm.interactive.rep_3);
                            interactivesLinks.fileUrlRep3 = INTERACTIVEURLFILEREP3;
                        }
                        if (this.itemForm.interactive.rep_4 && this.itemForm.interactive.rep_4.type) {
                            let INTERACTIVEURLFILEREP4 = URL.createObjectURL(this.itemForm.interactive.rep_4);
                            interactivesLinks.fileUrlRep4 = INTERACTIVEURLFILEREP4;
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesLinks.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    } else if (this.selected_question_interactive && this.selected_question_interactive.id === 5) {
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesTexteATrou.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    } else if (this.selected_question_interactive && this.selected_question_interactive.id === 6) {
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesQCM.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    } else if (this.selected_question_interactive && this.selected_question_interactive.id === 7) {
                        if (this.itemForm.interactive.elt_1 && this.itemForm.interactive.elt_1.type) {
                            let INTERACTIVEURLFILEELT1 = URL.createObjectURL(this.itemForm.interactive.elt_1);
                            interactivesRangerImages.fileUrlElt1 = INTERACTIVEURLFILEELT1;
                        }
                        if (this.itemForm.interactive.elt_2 && this.itemForm.interactive.elt_2.type) {
                            let INTERACTIVEURLFILEELT2 = URL.createObjectURL(this.itemForm.interactive.elt_2);
                            interactivesRangerImages.fileUrlElt2 = INTERACTIVEURLFILEELT2;
                        }
                        if (this.itemForm.interactive.elt_3 && this.itemForm.interactive.elt_3.type) {
                            let INTERACTIVEURLFILEELT3 = URL.createObjectURL(this.itemForm.interactive.elt_3);
                            interactivesRangerImages.fileUrlElt3 = INTERACTIVEURLFILEELT3;
                        }
                        if (this.itemForm.interactive.elt_4 && this.itemForm.interactive.elt_4.type) {
                            let INTERACTIVEURLFILEELT4 = URL.createObjectURL(this.itemForm.interactive.elt_4);
                            interactivesRangerImages.fileUrlElt4 = INTERACTIVEURLFILEELT4;
                        }
                        if (this.itemForm.interactive.rep_1 && this.itemForm.interactive.rep_1.type) {
                            let INTERACTIVEURLFILEREP1 = URL.createObjectURL(this.itemForm.interactive.rep_1);
                            interactivesRangerImages.fileUrlRep1 = INTERACTIVEURLFILEREP1;
                        }
                        if (this.itemForm.interactive.rep_2 && this.itemForm.interactive.rep_2.type) {
                            let INTERACTIVEURLFILEREP2 = URL.createObjectURL(this.itemForm.interactive.rep_2);
                            interactivesRangerImages.fileUrlRep2 = INTERACTIVEURLFILEREP2;
                        }
                        if (this.itemForm.interactive.rep_3 && this.itemForm.interactive.rep_3.type) {
                            let INTERACTIVEURLFILEREP3 = URL.createObjectURL(this.itemForm.interactive.rep_3);
                            interactivesRangerImages.fileUrlRep3 = INTERACTIVEURLFILEREP3;
                        }
                        if (this.itemForm.interactive.rep_4 && this.itemForm.interactive.rep_4.type) {
                            let INTERACTIVEURLFILEREP4 = URL.createObjectURL(this.itemForm.interactive.rep_4);
                            interactivesRangerImages.fileUrlRep4 = INTERACTIVEURLFILEREP4;
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesRangerImages.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                    }
                }
                this.$nextTick(() => {
                    let formDataItem = new FormData();
                    formDataItem.append('lesson_id', this.$route.params.id);
                    if (item.file != '') {
                        formDataItem.append('file' + item.order, item.file);
                    }
                    // Ajout des médias au formdata
                    //Images intéractives
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 1) {
                        formDataItem.append('interactives_images', JSON.stringify(interactivesImages));
                        if (interactivesImages.file != '') {
                            formDataItem.append('interactive_file', interactivesImages.file);
                        }
                        if (interactivesCursors.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesImages.explicationImage);
                        }
                    }
                    //Cursor
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 2) {
                        formDataItem.append('interactives_cursors', JSON.stringify(interactivesCursors));
                        if (interactivesCursors.file != '') {
                            formDataItem.append('interactive_file', interactivesCursors.file);
                        }
                        if (interactivesCursors.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesCursors.explicationImage);
                        }
                    }
                    // QCM Images
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 3) {
                        formDataItem.append('interactives_qcm_images', JSON.stringify(interactivesQCMImages));
                        if (interactivesQCMImages.qcm_image1 != '') {
                            formDataItem.append('qcm_image1', interactivesQCMImages.qcm_image1);
                        }
                        if (interactivesQCMImages.qcm_image2 != '') {
                            formDataItem.append('qcm_image2', interactivesQCMImages.qcm_image2);
                        }
                        if (interactivesQCMImages.qcm_image3 != '') {
                            formDataItem.append('qcm_image3', interactivesQCMImages.qcm_image3);
                        }
                        if (interactivesQCMImages.qcm_image4 != '') {
                            formDataItem.append('qcm_image4', interactivesQCMImages.qcm_image4);
                        }
                        if (interactivesQCMImages.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesQCMImages.explicationImage);
                        }
                    }

                    // Relier les points
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 4) {
                        formDataItem.append('interactives_links', JSON.stringify(interactivesLinks));
                        if (interactivesLinks.elt_1 && this.itemForm.interactive.elt_1.type) {
                            formDataItem.append('elt_1', interactivesLinks.elt_1);
                        }
                        if (interactivesLinks.elt_2 && this.itemForm.interactive.elt_2.type) {
                            formDataItem.append('elt_2', interactivesLinks.elt_2);
                        }
                        if (interactivesLinks.elt_3 && this.itemForm.interactive.elt_3.type) {
                            formDataItem.append('elt_3', interactivesLinks.elt_3);
                        }
                        if (interactivesLinks.elt_4 && this.itemForm.interactive.elt_4.type) {
                            formDataItem.append('elt_4', interactivesLinks.elt_4);
                        }
                        if (interactivesLinks.rep_1 && this.itemForm.interactive.rep_1.type) {
                            formDataItem.append('rep_1', interactivesLinks.rep_1);
                        }
                        if (interactivesLinks.rep_2 && this.itemForm.interactive.rep_2.type) {
                            formDataItem.append('rep_2', interactivesLinks.rep_2);
                        }
                        if (interactivesLinks.rep_3 && this.itemForm.interactive.rep_3.type) {
                            formDataItem.append('rep_3', interactivesLinks.rep_3);
                        }
                        if (interactivesLinks.rep_4 && this.itemForm.interactive.rep_4.type) {
                            formDataItem.append('rep_4', interactivesLinks.rep_4);
                        }
                        if (interactivesLinks.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesLinks.explicationImage);
                        }
                    }
                    // texte a trou
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 5) {
                        formDataItem.append('interactives_texte_a_trou', JSON.stringify(interactivesTexteATrou));
                        if (interactivesTexteATrou.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesTexteATrou.explicationImage);
                        }
                    }
                    // ranger images
                    if (this.selected_question_interactive && this.selected_question_interactive.id === 6) {
                        formDataItem.append('interactives_QCM', JSON.stringify(interactivesQCM));
                        if (interactivesQCM.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesQCM.explicationImage);
                        }
                    }

                    if (this.selected_question_interactive && this.selected_question_interactive.id === 7) {
                        formDataItem.append('interactives_ranger_images', JSON.stringify(interactivesRangerImages));
                        if (interactivesLinks.elt_1 && this.itemForm.interactive.elt_1.type) {
                            formDataItem.append('elt_1', interactivesRangerImages.elt_1);
                        }
                        if (interactivesLinks.elt_2 && this.itemForm.interactive.elt_2.type) {
                            formDataItem.append('elt_2', interactivesRangerImages.elt_2);
                        }
                        if (interactivesLinks.elt_3 && this.itemForm.interactive.elt_3.type) {
                            formDataItem.append('elt_3', interactivesRangerImages.elt_3);
                        }
                        if (interactivesLinks.elt_4 && this.itemForm.interactive.elt_4.type) {
                            formDataItem.append('elt_4', interactivesRangerImages.elt_4);
                        }
                        if (interactivesLinks.rep_1 && this.itemForm.interactive.rep_1.type) {
                            formDataItem.append('rep_1', interactivesRangerImages.rep_1);
                        }
                        if (interactivesLinks.rep_2 && this.itemForm.interactive.rep_2.type) {
                            formDataItem.append('rep_2', interactivesRangerImages.rep_2);
                        }
                        if (interactivesLinks.rep_3 && this.itemForm.interactive.rep_3.type) {
                            formDataItem.append('rep_3', interactivesRangerImages.rep_3);
                        }
                        if (interactivesLinks.rep_4 && this.itemForm.interactive.rep_4.type) {
                            formDataItem.append('rep_4', interactivesRangerImages.rep_4);
                        }
                        if (interactivesRangerImages.explicationImage != '') {
                            formDataItem.append('interactive_explication_image', interactivesRangerImages.explicationImage);
                        }
                    }
                    formDataItem.append('item', JSON.stringify(item));
                    // Affiche les valeurs
                    ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessons/addItems', formDataItem)
                        .then(result => {
                            if (this.itemForm.type === 'end') {
                                this.end = true;
                            }
                            item.id = result.data.id;
                            if (item.type === 'question') {
                                item.survey_item_id = result.data.survey_item_id;
                            } else if (item.type === 'questionnaire' || item.type === 'end') {
                                item.survey_id = result.data.survey_id;
                            } else if (
                                item.type === 'image' ||
                                item.type === 'video' ||
                                item.type === 'pdf' ||
                                item.type == 'video_interactive'
                            ) {
                                item.media_id = result.data.media_id;
                            } else if (item.type == 'question_interactive') {
                                item.interactive_item = result.data.interactive_item;
                                item.interactive_details = result.data.interactive_details;
                                item.title = result.data.interactive_details.title;
                            }
                            this.lessonItems.splice(item.order, 0, item);
                            this.$bvModal.hide('modal-lesson-items-form');
                            this.submit = false;
                            this.resetInputs();
                            URLFILE = null;
                            this.orderItems++;
                        })
                        .catch(() => {
                            this.submit = false;
                            this.$bvToast.toast(`Nous sommes désolés, une erreur a eu lieu lors de l'enregistrement.`, {
                                variant: 'warning',
                                title: 'Erreur'
                            });
                        });
                });
            }
        },
        updateItem() {
            if (!this.checkFormInputsValidity()) {
                return; // INPUT NOT ACCEPT
            } else {
                this.submit = true;
                const item = {
                    id: this.itemForm.id,
                    media_id: null,
                    survey_id: null,
                    survey_item_id: null,
                    type: this.itemForm.type,
                    order: this.itemForm.order,
                    title: this.itemForm.title,
                    description: this.itemForm.description,
                    text: this.itemForm.text,
                    message: this.itemForm.message,
                    goal: this.itemForm.goal,
                    link: this.itemForm.link,
                    test: this.itemForm.test,
                    file: this.itemForm.file,
                    fileUrl: null
                };
                if (this.itemForm.type == 'question' || this.itemForm.type == 'end' || this.itemForm.type == 'questionnaire') {
                    this.checkForm(item.test[item.test.length - 1]);
                }

                let formDataItem = new FormData();
                formDataItem.append('lesson_id', this.$route.params.id);
                if (item.type === 'question') {
                    item.survey_item_id = this.itemForm.survey_item_id;
                } else if (item.type === 'questionnaire' || item.type === 'end') {
                    item.survey_id = this.itemForm.survey_id;
                } else if (item.type === 'image' || item.type === 'video' || item.type === 'pdf' || item.type === 'video_interactive') {
                    item.media_id = this.itemForm.media_id;
                    if (this.itemForm.file) {
                        item.fileUrl = URL.createObjectURL(this.itemForm.file);
                        formDataItem.append('file' + item.order, this.itemForm.file);
                    } else {
                        item.fileUrl = this.itemForm.fileUrl;
                    }
                } else if (item.type === 'question_interactive') {
                    if (this.itemForm.interactive_item.id === 1) {
                        const interactivesImages = {
                            title: this.itemForm.interactive.title,
                            question: this.itemForm.interactive.question,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            label_1: this.itemForm.interactive.label1,
                            x_1: this.itemForm.interactive.x1,
                            y_1: this.itemForm.interactive.y1,
                            label_2: this.itemForm.interactive.label2,
                            x_2: this.itemForm.interactive.x2,
                            y_2: this.itemForm.interactive.y2,
                            label_3: this.itemForm.interactive.label3,
                            x_3: this.itemForm.interactive.x3,
                            y_3: this.itemForm.interactive.y3,
                            label_4: this.itemForm.interactive.label4,
                            x_4: this.itemForm.interactive.x4,
                            y_4: this.itemForm.interactive.y4,
                            file: this.itemForm.interactive.file
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesImages.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        // Cas images interactives :
                        Object.keys(interactivesImages).forEach(function(key) {
                            if (interactivesImages[key] === '') {
                                interactivesImages[key] = null;
                            }
                        });
                        if (this.itemForm.interactive.file) {
                            interactivesImages.fileUrl = URL.createObjectURL(this.itemForm.interactive.file);
                            formDataItem.append('interactiveFile', interactivesImages.file);
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesImages.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesImages.explicationImage);
                        }

                        formDataItem.append('interactives_images', JSON.stringify(interactivesImages));
                    } else if (this.itemForm.interactive_item.id === 2) {
                        const interactivesCursors = {
                            title: this.itemForm.interactive.title,
                            question: this.itemForm.interactive.question,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            elt_1: this.itemForm.interactive.elt_1,
                            elt_2: this.itemForm.interactive.elt_2,
                            elt_3: this.itemForm.interactive.elt_3,
                            elt_4: this.itemForm.interactive.elt_4,
                            b_rep: this.itemForm.interactive.b_rep,
                            file: this.itemForm.interactive.file
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesCursors.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        if (this.itemForm.interactive.file) {
                            interactivesCursors.fileUrl = URL.createObjectURL(this.itemForm.interactive.file);
                            formDataItem.append('interactiveFile', interactivesCursors.file);
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesCursors.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesCursors.explicationImage);
                        }
                        formDataItem.append('interactives_cursors', JSON.stringify(interactivesCursors));
                    } else if (this.itemForm.interactive_item.id === 3) {
                        const interactivesQCMImages = {
                            title: this.itemForm.interactive.title,
                            question: this.itemForm.interactive.question,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            qcm_image1: this.itemForm.interactive.qcm_image1,
                            qcm_image2: this.itemForm.interactive.qcm_image2,
                            qcm_image3: this.itemForm.interactive.qcm_image3,
                            qcm_image4: this.itemForm.interactive.qcm_image4,
                            elt_1_status: this.itemForm.interactive.qcm_checkbox1,
                            elt_2_status: this.itemForm.interactive.qcm_checkbox2,
                            elt_3_status: this.itemForm.interactive.qcm_checkbox3,
                            elt_4_status: this.itemForm.interactive.qcm_checkbox4
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesQCMImages.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        if (this.itemForm.interactive.qcm_image1) {
                            formDataItem.append('qcm_image1', interactivesQCMImages.qcm_image1);
                        }
                        if (this.itemForm.interactive.qcm_image2) {
                            formDataItem.append('qcm_image2', interactivesQCMImages.qcm_image2);
                        }
                        if (this.itemForm.interactive.qcm_image3) {
                            formDataItem.append('qcm_image3', interactivesQCMImages.qcm_image3);
                        }
                        if (this.itemForm.interactive.qcm_image4) {
                            formDataItem.append('qcm_image4', interactivesQCMImages.qcm_image4);
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesQCMImages.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesQCMImages.explicationImage);
                        }
                        formDataItem.append('interactives_qcm_images', JSON.stringify(interactivesQCMImages));
                    } else if (this.itemForm.interactive_item.id === 4) {
                        const interactivesLinks = {
                            title: this.itemForm.interactive.title,
                            question: this.itemForm.interactive.question,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            elt_1: this.itemForm.interactive.elt_1,
                            elt_2: this.itemForm.interactive.elt_2,
                            elt_3: this.itemForm.interactive.elt_3,
                            elt_4: this.itemForm.interactive.elt_4,
                            rep_1: this.itemForm.interactive.rep_1,
                            rep_2: this.itemForm.interactive.rep_2,
                            rep_3: this.itemForm.interactive.rep_3,
                            rep_4: this.itemForm.interactive.rep_4
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesLinks.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        if (this.itemForm.interactive.elt_1 && this.itemForm.interactive.elt_1.type) {
                            formDataItem.append('elt_1', interactivesLinks.elt_1);
                        }
                        if (this.itemForm.interactive.elt_2 && this.itemForm.interactive.elt_2.type) {
                            formDataItem.append('elt_2', interactivesLinks.elt_2);
                        }
                        if (this.itemForm.interactive.elt_3 && this.itemForm.interactive.elt_3.type) {
                            formDataItem.append('elt_3', interactivesLinks.elt_3);
                        }
                        if (this.itemForm.interactive.elt_4 && this.itemForm.interactive.elt_4.type) {
                            formDataItem.append('elt_4', interactivesLinks.elt_4);
                        }
                        if (this.itemForm.interactive.rep_1 && this.itemForm.interactive.rep_1.type) {
                            formDataItem.append('rep_1', interactivesLinks.rep_1);
                        }
                        if (this.itemForm.interactive.rep_2 && this.itemForm.interactive.rep_2.type) {
                            formDataItem.append('rep_2', interactivesLinks.rep_2);
                        }
                        if (this.itemForm.interactive.rep_3 && this.itemForm.interactive.rep_3.type) {
                            formDataItem.append('rep_3', interactivesLinks.rep_3);
                        }
                        if (this.itemForm.interactive.rep_4 && this.itemForm.interactive.rep_4.type) {
                            formDataItem.append('rep_4', interactivesLinks.rep_4);
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesLinks.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesLinks.explicationImage);
                        }
                        formDataItem.append('interactives_links', JSON.stringify(interactivesLinks));
                    } else if (this.itemForm.interactive_item.id === 5) {
                        const interactivesTexteATrou = {
                            title: this.itemForm.interactive.title,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            elt_1: this.itemForm.interactive.elt_1,
                            elt_2: this.itemForm.interactive.elt_2,
                            rep_1: this.itemForm.interactive.rep_1,
                            rep_2: this.itemForm.interactive.rep_2,
                            rep_3: this.itemForm.interactive.rep_3
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesTexteATrou.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        Object.keys(interactivesTexteATrou).forEach(function(key) {
                            if (interactivesTexteATrou[key] === '') {
                                interactivesTexteATrou[key] = null;
                            }
                        });
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesTexteATrou.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesTexteATrou.explicationImage);
                        }
                        formDataItem.append('interactives_texte_a_trou', JSON.stringify(interactivesTexteATrou));
                    } else if (this.itemForm.interactive_item.id === 6) {
                        const interactivesQCM = {
                            title: this.itemForm.interactive.title,
                            question: this.itemForm.interactive.question,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            elt_1: this.itemForm.interactive.elt_1,
                            elt_2: this.itemForm.interactive.elt_2,
                            elt_3: this.itemForm.interactive.elt_3,
                            elt_4: this.itemForm.interactive.elt_4,
                            elt_1_status: this.itemForm.interactive.qcm_checkbox1,
                            elt_2_status: this.itemForm.interactive.qcm_checkbox2,
                            elt_3_status: this.itemForm.interactive.qcm_checkbox3,
                            elt_4_status: this.itemForm.interactive.qcm_checkbox4
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesQCM.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        Object.keys(interactivesQCM).forEach(function(key) {
                            if (interactivesQCM[key] === '') {
                                interactivesQCM[key] = null;
                            }
                        });
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesQCM.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesQCM.explicationImage);
                        }
                        formDataItem.append('interactives_QCM', JSON.stringify(interactivesQCM));
                    } else if (this.itemForm.interactive_item.id === 7) {
                        const interactivesRangerImages = {
                            title: this.itemForm.interactive.title,
                            question: this.itemForm.interactive.question,
                            consigne: this.itemForm.interactive.consigne,
                            explicationText: this.explicationTextFormat(),
                            elt_1: this.itemForm.interactive.elt_1,
                            elt_2: this.itemForm.interactive.elt_2,
                            elt_3: this.itemForm.interactive.elt_3,
                            elt_4: this.itemForm.interactive.elt_4,
                            rep_1: this.itemForm.interactive.rep_1,
                            rep_2: this.itemForm.interactive.rep_2,
                            rep_3: this.itemForm.interactive.rep_3,
                            rep_4: this.itemForm.interactive.rep_4
                        };
                        if (this.itemForm.interactive.explicationImage !== null) {
                            interactivesRangerImages.explicationImage = this.itemForm.interactive.explicationImage;
                        }
                        if (this.itemForm.interactive.elt_1 && this.itemForm.interactive.elt_1.type) {
                            formDataItem.append('elt_1', interactivesRangerImages.elt_1);
                        }
                        if (this.itemForm.interactive.elt_2 && this.itemForm.interactive.elt_2.type) {
                            formDataItem.append('elt_2', interactivesRangerImages.elt_2);
                        }
                        if (this.itemForm.interactive.elt_3 && this.itemForm.interactive.elt_3.type) {
                            formDataItem.append('elt_3', interactivesRangerImages.elt_3);
                        }
                        if (this.itemForm.interactive.elt_4 && this.itemForm.interactive.elt_4.type) {
                            formDataItem.append('elt_4', interactivesRangerImages.elt_4);
                        }
                        if (this.itemForm.interactive.rep_1 && this.itemForm.interactive.rep_1.type) {
                            formDataItem.append('rep_1', interactivesRangerImages.rep_1);
                        }
                        if (this.itemForm.interactive.rep_2 && this.itemForm.interactive.rep_2.type) {
                            formDataItem.append('rep_2', interactivesRangerImages.rep_2);
                        }
                        if (this.itemForm.interactive.rep_3 && this.itemForm.interactive.rep_3.type) {
                            formDataItem.append('rep_3', interactivesRangerImages.rep_3);
                        }
                        if (this.itemForm.interactive.rep_4 && this.itemForm.interactive.rep_4.type) {
                            formDataItem.append('rep_4', interactivesRangerImages.rep_4);
                        }
                        if (this.itemForm.interactive.explicationImage) {
                            interactivesRangerImages.explicationImage = this.itemForm.interactive.explicationImage;
                            formDataItem.append('interactive_explication_image', interactivesRangerImages.explicationImage);
                        }
                        formDataItem.append('interactives_ranger_images', JSON.stringify(interactivesRangerImages));
                    }
                }
                formDataItem.append('item', JSON.stringify(item));

                ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessonsItems/updateItem', formDataItem)
                    .then(() => {
                        if (item.type === 'end') {
                            this.end = true;
                        }
                        this.lessonItems.splice(item.order, 1, item);
                        this.$bvModal.hide('modal-lesson-items-form');
                        this.resetInputs();
                        this.$store.dispatch(LIST_LESSON_ITEMS, this.$route.params.id).then(() => {
                            this.resetComposition();
                            this.initCompostition(this.getterLessonItems);
                        });
                        this.$store.dispatch(LIST_LESSON, this.$route.params.id);
                        this.$forceUpdate();
                        this.submit = false;
                    })
                    .catch(() => {
                        this.submit = false;
                        this.$bvToast.toast(`Nous sommes désolés, une erreur a eu lieu lors de l'enregistrement.`, {
                            variant: 'warning',
                            title: 'Erreur'
                        });
                    });
            }
        },
        validComposition() {
            this.$router.push({
                name: 'classes/edit',
                params: { id: this.lesson.classe_id }
            });
        },
        endComposition(item_order) {
            if (this.lessonItems[item_order].type == 'end' || this.lessonItems[item_order].type == 'questionnaire') {
                const existEnd = this.lessonItems.find(element => element.type == 'end');
                if (!existEnd) {
                    ApiService.post(process.env.VUE_APP_API_URL + '/academy/survey/switchType', {
                        survey_id: this.lessonItems[item_order].survey_id
                    }).then(() => {
                        this.end = true;
                        this.lessonItems[item_order].type = 'end';
                    });
                } else {
                    if (existEnd.order == item_order) {
                        ApiService.post(process.env.VUE_APP_API_URL + '/academy/survey/switchType', {
                            survey_id: this.lessonItems[item_order].survey_id
                        }).then(() => {
                            this.end = false;
                            this.lessonItems[item_order].type = 'questionnaire';
                        });
                    } else {
                        ApiService.post(process.env.VUE_APP_API_URL + '/academy/survey/switchType', {
                            survey_id: this.lessonItems[existEnd.order].survey_id
                        }).then(() => {
                            this.lessonItems[existEnd.order].type = 'questionnaire';
                            ApiService.post(process.env.VUE_APP_API_URL + '/academy/survey/switchType', {
                                survey_id: this.lessonItems[item_order].survey_id
                            }).then(() => {
                                this.end = true;
                                this.lessonItems[item_order].type = 'end';
                            });
                        });
                    }
                }
            }
        },
        ///////////////////////////////////////////////////////////////////
        ///////////////////////////| RESETS |//////////////////////////////
        // RESET LA LISTE DES ITEMS (REINITIALISE LA COMPOSITION)
        resetComposition() {
            this.lessonItems = [{ title: 'Début de la composition', order: 0, type: 'start' }];
        },
        resetErrors() {
            for (const [key, value] of Object.entries(this.error)) {
                if (value !== null) {
                    this.error[key] = null;
                }
            }
        },
        // RESET DES VALEURS DE l'ITEM EN COURS DE CREATION
        resetInputs() {
            this.resetErrors();
            this.resetInputsStates();
            this.resetQuestionsForm();
            if (this.itemForm.interactive) {
                this.resetInteractives();
                this.resetSwitch();
            }
            this.interactive_id = null;
            this.itemForm.type = null;
            this.itemForm.id = null;
            this.itemForm.title = null;
            this.itemForm.description = null;
            this.itemForm.order = null;
            this.itemForm.text = null;
            this.itemForm.link = null;
            this.itemForm.message = null;
            this.itemForm.test = [];
            this.itemForm.file = null;
            this.selected_question_interactive = null;
            this.update = false;
            this.showAnswers = false;
        },
        resetSwitch(side) {
            if (side === 'left') {
                this.itemForm.interactive.elt_1 = null;
                this.itemForm.interactive.elt_2 = null;
                this.itemForm.interactive.elt_3 = null;
                this.itemForm.interactive.elt_4 = null;
            } else if (side === 'right') {
                this.itemForm.interactive.rep_1 = null;
                this.itemForm.interactive.rep_2 = null;
                this.itemForm.interactive.rep_3 = null;
                this.itemForm.interactive.rep_4 = null;
            } else {
                this.itemForm.interactive.elt_1 = null;
                this.itemForm.interactive.elt_2 = null;
                this.itemForm.interactive.elt_3 = null;
                this.itemForm.interactive.elt_4 = null;
                this.itemForm.interactive.rep_1 = null;
                this.itemForm.interactive.rep_2 = null;
                this.itemForm.interactive.rep_3 = null;
                this.itemForm.interactive.rep_4 = null;
            }
            this.forceRerender();
        },
        resetExplications() {
            this.itemForm.interactive.explicationText = null;
            this.itemForm.interactive.explicationImage = null;
            this.itemForm.interactive.explicationImage_placeholder = 'Choisissez une image ou déposez-la ici';
            // this.$forceUpdate(); Me permet de forcer le rendu de la vue lorsque l'on utilise le switch
            this.$forceUpdate();
        },
        resetInteractives() {
            this.itemForm.interactive.title = null;
            this.itemForm.interactive.question = null;
            this.itemForm.interactive.consigne = null;
            this.itemForm.interactive.explication = [];
            this.itemForm.interactive.explicationText = null;
            this.itemForm.interactive.explicationImage = null;
            this.itemForm.interactive.file = null;
            this.itemForm.interactive.label1 = null;
            this.itemForm.interactive.x1 = null;
            this.itemForm.interactive.y1 = null;
            this.itemForm.interactive.label2 = null;
            this.itemForm.interactive.x2 = null;
            this.itemForm.interactive.y2 = null;
            this.itemForm.interactive.label3 = null;
            this.itemForm.interactive.x3 = null;
            this.itemForm.interactive.y3 = null;
            this.itemForm.interactive.label4 = null;
            this.itemForm.interactive.x4 = null;
            this.itemForm.interactive.y4 = null;
            this.itemForm.interactive.b_rep = null;
            this.itemForm.interactive.qcm_image1 = null;
            this.itemForm.interactive.qcm_image2 = null;
            this.itemForm.interactive.qcm_image3 = null;
            this.itemForm.interactive.qcm_image4 = null;
            this.itemForm.interactive.qcm_checkbox1 = 0;
            this.itemForm.interactive.qcm_checkbox2 = 0;
            this.itemForm.interactive.qcm_checkbox3 = 0;
            this.itemForm.interactive.qcm_checkbox4 = 0;
            this.itemForm.interactive.qcm_image1_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.qcm_image2_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.qcm_image3_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.qcm_image4_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.checked_left = false;
            this.itemForm.interactive.checked_right = false;
            this.itemForm.interactive.elt_1_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.elt_2_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.elt_3_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.elt_4_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.rep_1_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.rep_2_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.rep_3_placeholder = 'Choisissez une image ou déposez-la ici';
            this.itemForm.interactive.rep_4_placeholder = 'Choisissez une image ou déposez-la ici';
        },
        // RESET DES STATUS DE l'ITEM EN COURS DE CREATION
        resetInputsStates() {
            this.statesItemForm.title = null;
            this.statesItemForm.description = null;
            this.statesItemForm.text = null;
            this.statesItemForm.link = null;
            this.statesItemForm.test = null;
            this.statesItemForm.file = null;
            this.statesItemForm.nextFormId = 1;
            this.statesItemForm.next = false;
            this.statesItemForm.previous = false;
            this.statesItemForm.onFocus = 0;
            this.statesItemForm.interactive.title = null;
            this.statesItemForm.interactive.question = null;
            this.statesItemForm.interactive.file = null;
            this.statesItemForm.interactive.point1 = null;
            this.statesItemForm.interactive.point2 = null;
            this.statesItemForm.interactive.point3 = null;
            this.statesItemForm.interactive.point4 = null;
            this.statesItemForm.interactive.elt_1 = null;
            this.statesItemForm.interactive.elt_2 = null;
            this.statesItemForm.interactive.elt_3 = null;
            this.statesItemForm.interactive.elt_4 = null;
            this.statesItemForm.interactive.rep_1 = null;
            this.statesItemForm.interactive.rep_2 = null;
            this.statesItemForm.interactive.rep_3 = null;
            this.statesItemForm.interactive.rep_4 = null;
            this.statesItemForm.interactive.b_rep = null;
            this.statesItemForm.interactive.qcm_image1 = null;
            this.statesItemForm.interactive.qcm_image2 = null;
            this.statesItemForm.interactive.qcm_image3 = null;
            this.statesItemForm.interactive.qcm_image4 = null;
            this.statesItemForm.interactive.qcm_checkbox = null;
        },
        ///////////////////////////////////////////////////////////////////
        //////////////////| METHODS FOR SURVEY FORM |//////////////////////
        // Créer un nouvelle question
        newQuestionForm() {
            this.statesItemForm.next = true;
            this.validQuestionsForm(this.statesItemForm.onFocus);
            if (this.error.state === true) {
                return;
            }
            this.resetErrors();
            this.resetQuestionsForm();
            this.statesItemForm.onFocus = this.statesItemForm.nextFormId;
            this.statesItemForm.nextFormId++;
        },
        // RESET LES QUESTIONS ( SUPPRIME TOUT LES QUESTIONS ET LEURS RÉPONSES)
        resetQuestionsForm() {
            this.inputQuestionItem.id = null;
            this.inputQuestionItem.created_at = null;
            this.inputQuestionItem.question = null;
            this.inputQuestionItem.answerA = null;
            this.inputQuestionItem.answerB = null;
            this.inputQuestionItem.answerC = null;
            this.inputQuestionItem.answerD = null;
            this.inputQuestionItem.goodAnswers = [];
            this.resetAnimation();
        },
        // Récup les données du form d'une question existante pour l'afficher
        focusQuestionForm(questionFormID) {
            this.resetQuestionsForm();
            if (this.itemForm.test[questionFormID]) {
                this.inputQuestionItem.question = this.itemForm.test[questionFormID].question;
                this.inputQuestionItem.answerA = this.itemForm.test[questionFormID].answerA;
                this.inputQuestionItem.answerB = this.itemForm.test[questionFormID].answerB;
                this.inputQuestionItem.answerC = this.itemForm.test[questionFormID].answerC;
                this.inputQuestionItem.answerD = this.itemForm.test[questionFormID].answerD;
                this.inputQuestionItem.goodAnswers = this.itemForm.test[questionFormID].goodAnswers;
                this.inputQuestionItem.id = this.itemForm.test[questionFormID].id;
                this.inputQuestionItem.created_at = this.itemForm.test[questionFormID].created_at;
            }
        },
        // AFFICHE LA QUESTION SUIVANTE
        nextQuestionForm() {
            if (this.itemForm.test[parseInt(this.statesItemForm.onFocus) + 1] != null) {
                this.statesItemForm.next = true;
                this.validQuestionsForm(this.statesItemForm.onFocus);
                if (this.error.state === true) {
                    return;
                }
                this.statesItemForm.onFocus++;
                this.focusQuestionForm(this.statesItemForm.onFocus);
            }
            this.resetErrors();
        },
        // AFFICHE LA QUESTION PRECEDENTE
        previousQuestionForm(validQuestion = true) {
            if (this.statesItemForm.onFocus != 0) {
                this.statesItemForm.previous = true;
                if (validQuestion) {
                    this.validQuestionsForm(this.statesItemForm.onFocus);
                }
                if (this.error.state === true) {
                    return;
                }
                this.statesItemForm.onFocus--;
                this.focusQuestionForm(this.statesItemForm.onFocus);
            }
            this.resetErrors();
        },
        // AJOUTE LA QUESTION A LA LISTE DES QUESTION DU FORM
        validQuestionsForm(questionFormID) {
            const question = {
                id: this.inputQuestionItem.id,
                created_at: this.inputQuestionItem.created_at,
                question: this.inputQuestionItem.question,
                answerA: this.inputQuestionItem.answerA,
                answerB: this.inputQuestionItem.answerB,
                answerC: this.inputQuestionItem.answerC,
                answerD: this.inputQuestionItem.answerD,
                goodAnswers: this.inputQuestionItem.goodAnswers
            };
            this.checkForm(question);

            let nbDelete = null;
            this.itemForm.test[questionFormID] ? (nbDelete = 1) : (nbDelete = 0);
            this.itemForm.test.splice(questionFormID, nbDelete, question);
        },
        // fonction qui viens checker que le formulaire des questions est OK niveau champs requis
        checkForm(question) {
            // vérification que le champs question est rempli
            if (question.question) {
                this.error.question = true;
            } else {
                this.error.state = true;
                this.error.question = false;
            }

            // Vérification que les inputs A et B sont remplis
            if (question.answerA) {
                this.error.answerACheckBox = true;
            } else {
                this.error.state = true;
                this.error.answerACheckBox = false;
            }
            if (question.answerB) {
                this.error.answerBCheckBox = true;
            } else {
                this.error.state = true;
                this.error.answerBCheckBox = false;
            }
            if (question.answerC) {
                this.error.answerCCheckBox = true;
            } else {
                this.error.answerCCheckBox = null;
            }
            if (question.answerD) {
                // génére une erreur quand le champs D est rempli mais que le champs C ne l'est pas
                if (question.answerC === null || question.answerC === '') {
                    this.error.state = true;
                    this.error.answerCCheckBox = false;
                } else {
                    this.error.answerCCheckBox = true;
                }
                this.error.answerDCheckBox = true;
            } else {
                this.error.answerDCheckBox = null;
            }

            // Vérification que au moins une réponse est bonne
            if (question.goodAnswers.length === 0) {
                const error = {
                    id: 'Minimum réponse requise',
                    message: 'Vous devez sélectionner au moins une bonne réponse'
                };

                this.$bvToast.toast(error.message, {
                    variant: 'warning',
                    title: error.id
                });
                this.error.state = true;
                this.error.countGoodAnswers = false;
            } else {
                this.error.countGoodAnswers = true;
            }

            // Vérifie que si un checkbox est sélectionné une réponse est aussi renseignée
            question.goodAnswers.map(goodAnswer => {
                if (goodAnswer === 'A') {
                    if (!question.answerA) {
                        this.error.state = true;
                        this.error.answerACheckBox = false;
                    } else {
                        this.error.answerACheckBox = true;
                    }
                } else if (goodAnswer === 'B') {
                    if (!question.answerB) {
                        this.error.state = true;
                        this.error.answerBCheckBox = false;
                    } else {
                        this.error.answerBCheckBox = true;
                    }
                } else if (goodAnswer === 'C') {
                    if (!question.answerC) {
                        this.error.state = true;
                        this.error.answerCCheckBox = false;
                    } else {
                        this.error.answerCCheckBox = true;
                    }
                } else if (goodAnswer === 'D') {
                    if (!question.answerD) {
                        this.error.state = true;
                        this.error.answerDCheckBox = false;
                    } else {
                        this.error.answerDCheckBox = true;
                    }
                }
            });

            if (
                this.error.question &&
                this.error.answerACheckBox &&
                this.error.answerBCheckBox &&
                this.error.countGoodAnswers &&
                (this.error.answerCCheckBox || this.error.answerCCheckBox === null) &&
                (this.error.answerDCheckBox || this.error.answerDCheckBox === null)
            ) {
                this.error.state = false;
            }

            if (this.error.state) {
                return;
            }
        },
        // REINITIALISE L'ANIMATION LORS DU CHANGEMENT DE FORM DES QUESTIONS
        resetAnimation() {
            this.statesItemForm.next = false;
            this.statesItemForm.previous = false;
        },
        ///////////////////////////////////////////////////////////////////
        ///////////////////////////| EVENTS |//////////////////////////////
        showAllItem(item_oder) {
            this.detailsShowing == item_oder ? (this.detailsShowing = null) : (this.detailsShowing = item_oder);
        }
        ///////////////////////////////////////////////////////////////////
    }
};
</script>
<style scoped>
.slide {
    overflow: hidden;
    position: relative;
}

.slide-previous {
    animation: slide-previous 0.5s linear;
}

.slide-next {
    animation: slide-next 0.5s linear;
}

@keyframes slide-next {
    0% {
        transform: translate(0, 0);
    }
    49% {
        opacity: 0;
        transform: translate(-100%, 0);
    }
    51% {
        opacity: 0;
        transform: translate(200%, 0);
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes slide-previous {
    0% {
        transform: translate(0, 0);
    }
    49% {
        opacity: 0;
        transform: translate(200%, 0);
    }
    51% {
        opacity: 0;
        transform: translate(-100%, 0);
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.scrollPanel::-webkit-scrollbar-track {
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
    z-index: 9999;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
